import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const DateTimeInput = ({ setStartDate, startDate, notification = "" }) => {
  const [selectedDateTime, setSelectedDateTime] = useState(new Date());

  const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);

    return currentDate.getTime() < selectedDate.getTime();
  };

  const handleDateChange = (date) => {
    setSelectedDateTime(date);
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: true,
    };

    const formattedDateTime = new Intl.DateTimeFormat("en-US", options).format(
      date
    );

    setStartDate(formattedDateTime);
  };

  return (
    <div>
      <DatePicker
        selected={selectedDateTime}
        onChange={(date) => handleDateChange(date)}
        showTimeSelect
        timeFormat="hh:mm aa"
        timeIntervals={5}
        dateFormat="dd/MM/yyyy h:mm aa"
        minDate={new Date()}
        filterTime={filterPassedTime}
        className="form-control"
      />

      {selectedDateTime && (
        <p>
          {notification !== "" ? notification : `Published`} Date and Time:
          <br /> {selectedDateTime.toLocaleString()}
        </p>
      )}
    </div>
  );
};

export default DateTimeInput;







// import React, { useEffect, useState } from "react";

// const DateTimeInput = ({ setStartDate, startDate, notification = "" }) => {
//   const [selectedDateTime, setSelectedDateTime] = useState("");
//   const [formattedDateTime, setFormattedDateTime] = useState("");

//   const formatDateTime = () => {
//     if (!selectedDateTime) return
//     const inputDate = new Date(selectedDateTime);

//     const options = {
//       year: "numeric",
//       month: "long",
//       day: "numeric",
//       hour: "numeric",
//       minute: "numeric",
//       second: "numeric",
//       hour12: true,
//     };

//     const formattedDateTime = new Intl.DateTimeFormat("en-US", options).format(
//       inputDate
//     );

//     setFormattedDateTime(formattedDateTime);
//     setStartDate(formattedDateTime);
//   };

//   const [minDateTime, setMinDateTime] = useState('');

//   useEffect(() => {
//     // Function to format the current date and time as 'YYYY-MM-DDTHH:MM'
//     const getFormattedNow = () => {
//       const now = new Date();
//       const year = now.getFullYear();
//       const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are zero-based
//       const day = String(now.getDate()).padStart(2, '0');
//       const hours = String(now.getHours()).padStart(2, '0');
//       const minutes = String(now.getMinutes()).padStart(2, '0');

//       return `${year}-${month}-${day}T${hours}:${minutes}`;
//     };

//     setMinDateTime(getFormattedNow());
//   }, []);


//   return (
//     <div>
//       {/* <label htmlFor="datetime">Select Date and Time:</label> */}
//       <input
//         className="form-control"
//         type="datetime-local"
//         id="datetime"
//         min={minDateTime}
//         value={selectedDateTime}
//         onChange={(e) => {
//           setSelectedDateTime(e.target.value);
//         }}
//         onBlur={formatDateTime}
//       />

//       {formattedDateTime && (
//         <p>
//           {notification !== "" ? notification : `Published`} Date and Time:
//           <br /> {formattedDateTime}
//         </p>
//       )}
//     </div>
//   );
// };

// export default DateTimeInput;


