import React, { useEffect, useState } from "react";
import moment from "moment";
import Loader from "../../common/utils/loader";
import { ConvertArrayTOString } from "../../common/utils/arrayToString";
import config from "../../../config";
import { apiEndpoints } from "../../common/api/apiEndpoints";

import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

import { utils as XLSXUtils, writeFile as writeExcelFile } from 'xlsx';
import Select from 'react-select'
import makeAnimated from "react-select/animated";
const animatedComponents = makeAnimated();

const AuthorReport = () => {
    const oneWeekAgo = moment(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)).format('YYYY-MM-DD')
    const today = moment(new Date()).format('YYYY-MM-DD')

    const [storiesList, setStoriesList] = useState([])
    const [authors, setAuthors] = useState([])
    const [loading, setLoading] = useState(false)
    const [selectedAuthor, setSelectedAuthor] = useState('')
    const [startDate, setStartDate] = useState(oneWeekAgo)
    const [endDate, setEndDate] = useState(today)
    const [totalViews, setTotalViews] = useState(0)
    const [showDatePicker, setShowDatePicker] = useState(false)

    const [selectedDateRange, setSelectedDateRange] = useState([
        {
            startDate: new Date(Date.now() - 7 * 24 * 60 * 60 * 1000),
            endDate: new Date(),
            key: 'selection'
        }
    ]);

    const handleSelect = async (ranges) => {
        setSelectedDateRange([ranges.selection]);
        const sDate = ranges?.selection?.startDate
        const eDate = ranges?.selection?.endDate

        setStartDate(moment(sDate)?.format('YYYY-MM-DD'))
        setEndDate(moment(eDate)?.format('YYYY-MM-DD'))
    };

    useEffect(() => {
        if (selectedAuthor) fetchStories()
    }, [selectedAuthor])

    useEffect(() => {
        if (startDate && endDate) fetchStories()
    }, [startDate, endDate])

    const fetchAuthors = async () => {
        try {
            const url = `${config.apiUrl}/${apiEndpoints?.user}`
            const response = await fetch(url)
            const data = await response.json()
            const arr = []
            data?.users.forEach((element) => {
                if (element?.user_role?.name === "author" || element?.user_role?.name === "Admin" || element?.user_role?.name === "Editor" || element?.user_role?.name === "Guest Contributor") {
                    arr?.push({
                        label: element?.name,
                        value: element?.name,
                        id: element?._id,
                    });
                }
            });

            setAuthors(arr)
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }

    const fetchStories = async () => {
        try {
            setLoading(true)
            let url = `${config.apiUrl}/stories/author/stories?`

            if (selectedAuthor) url += `&author=${selectedAuthor}`
            if (startDate) url += `&startDate=${startDate}`
            if (endDate) url += `&endDate=${endDate}`

            const response = await fetch(url)
            const data = await response.json()
            setStoriesList(data?.stories)

            const views = data?.stories?.reduce((acc, current) => acc + (current?.range_count || 0), 0)
            setTotalViews(views)

            if (data) {
                setLoading(false)
                console.log({ len: data?.stories?.length })
            }

        } catch (error) {
            console.error("Error fetching data:", error);
        }
        finally {
            // setLoading(false)
        }
    }

    const convertToExcel = (stories) => {
        const data = storiesList.map(item => ({
            name: item.author_name,
            story_count: item?.storyCount?.toString(),
            submitted_count: item?.submittedCount?.toString(),
            view_count: item.range_count?.toString(),
        }));

        const columnWidths = [
            { wch: 50 },
            { wch: 50 },
            { wch: 50 },
            { wch: 50 }
        ];

        const worksheet = XLSXUtils.json_to_sheet(data);
        worksheet['!cols'] = columnWidths;

        const workbook = XLSXUtils.book_new();
        XLSXUtils.book_append_sheet(workbook, worksheet, 'Sheet1');
        const sDate = moment(startDate).format('DD-MM-YYYY')
        const eDate = moment(endDate).format('DD-MM-YYYY')
        writeExcelFile(workbook, `author_report_from_${sDate}_to_${eDate}.xlsx`);
    };

    useEffect(() => {
        const fetchData = async () => {
            fetchAuthors()
            // fetchStories()
        }
        fetchData()
    }, [])

    const checkStoryIsPublished = (story) => {
        if (story?.status === 'scheduled') {
            const currentTime = new Date().toISOString()
            const publishTime = story?.publish_date

            if (publishTime && publishTime <= currentTime) {
                return true
            }
        }
        return false
    }

    const openStoryReport = (authorName) => {
        const url = `/story-report?authorName=${authorName}&startDate=${startDate}&endDate=${endDate}`;
        window.open(url, '_blank');
    }

    return (
        <div style={{ backgroundColor: '#f9f9f9' }}>
            <div className="content" style={{ backgroundColor: '#f9f9f9' }}>
                <div className="content">
                    <div className="card col-md-12 m-auto p-30">

                        <div className="card-body">
                            <div className='authors-container'>
                                <Select
                                    options={authors}
                                    components={animatedComponents}
                                    value={authors?.find(item => item?.value === selectedAuthor)}
                                    placeholder="Select Authors"
                                    onChange={e => {
                                        setSelectedAuthor(e.value)
                                    }}
                                />
                            </div>

                            <div className='export-excel-btn'>
                                {
                                    storiesList?.length > 0 &&
                                    <button className="btn-primary export-btn" onClick={() => convertToExcel(storiesList)}>Export to Excel</button>
                                }
                                <div className="fw-bold"><span className=" mr-4">{"Total Views:"}</span>{totalViews}</div>
                            </div>

                            <button className="btn-primary mb-40" onClick={() => { setShowDatePicker(!showDatePicker) }}>Select Date Range</button>

                            {
                                showDatePicker &&
                                <div className="date-picker">
                                    <DateRangePicker
                                        ranges={selectedDateRange}
                                        onChange={handleSelect}
                                    />
                                </div>
                            }


                            <div className="table-responsive">
                                <table className="table table-hover">
                                    <thead>
                                        <tr>
                                            <th>Author Name</th>
                                            <th>Story Count</th>
                                            <th>Submitted Count</th>
                                            <th>Total Views</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {!storiesList?.length > 0 || loading ? (
                                            <Loader />
                                        ) : (
                                            storiesList?.map((author, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td style={{ cursor: 'pointer' }} onClick={() => { openStoryReport(author?.author_name) }}>{author?.author_name}</td>
                                                        <td>{author?.storyCount}</td>
                                                        <td>{author?.submittedCount}</td>
                                                        <td>{author?.range_count}</td>
                                                    </tr>
                                                );
                                            })
                                        )}
                                    </tbody>
                                </table>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default AuthorReport;
