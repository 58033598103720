import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { Tooltip as ReactTooltip } from "react-tooltip";
import DropBox from "../../common/drop-zone";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { selectOption } from "../../common/common-data/alphabets";
import { DeleteApi, GetListApi } from "../../common/api/api";
import { apiEndpoints } from "../../common/api/apiEndpoints";
import QuillEditor from "./reactQuill";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Swal from "sweetalert2";
import { countWordsAndChars } from "../../common/count-words-and-characters";
import LinkPreview from "./linkPreview";
const animatedComponents = makeAnimated();

const TextEditorComponent = (props) => {
  const {
    setValue,
    cards,
    setCards,
    isImgEditorShown,
    setIsImgEditorShown,
    selectedFile,
    setSelectedFile,
    setIsImgEditorShownForEditor,
    handleSubCardChange,
    setCardIndexObj,
    currentImgId,
    setCurrentImgId,
    errors,
    register,
    isLoading,
    setIsLoading,
    getValues,
    onAutoSaveStory,
    imgUrl,
    setImgUrl,
    editEnabled
  } = props;
  const [isShowHeader, setIsShowHeader] = useState(false);
  const [storyList, setStoryList] = useState(selectOption);
  const [selectedStoryList, setSelectedStoryList] = useState([]);
  const fetchOurStory = async () => {
    const response = await GetListApi(`${apiEndpoints.stories}`, {
      pagination: false,
    });
    if (response?.stories) {
      let arr = [];
      if (response.stories.length > 0) {
        response.stories.forEach((element) => {
          arr?.push({
            label: element?.title,
            value: element?._id,
          });
          setStoryList(arr);
        });
      }
    }
  };
  useEffect(() => {
    fetchOurStory();
  }, []);
  const HandleSelectChange = (type, e, cardIndex, subCardIndex) => {
    if (type === "story") {
      handleSubCardChange(cardIndex, subCardIndex, "story", e?.value);
      setSelectedStoryList(e);
    }
  };
  // const [imgUrl, setImgUrl] = useState("");
  const [isComponent, setIsComponent] = useState("textEditor");
  const [files, setFiles] = useState([]);
  // const { register, control, handleSubmit, setValue: setForm } = useForm();

  // Function to add a new card
  const addCard = (top = 0) => {
    if (top) {
      setCards([
        {
          sub_card: [
            {
              type: "Image",
              title: "",
              attributes: "",
              actionUrl: "",
              description: "",
              isExternal: false,
              noFollow: false,
              question: "",
              question_attributes: "",
              answer: "",
              answer_attributes: "",
              summary: "",
              images: "",
              img_caption: "",
              img_attribution: "",
              link_story: "",
            },
            {
              type: "Text",
              title: "",
              attributes: "",
              actionUrl: "",
              description: "",
              isExternal: false,
              noFollow: false,
              question: "",
              question_attributes: "",
              answer: "",
              answer_attributes: "",
              summary: "",
              images: "",
              img_caption: "",
              img_attribution: "",
              img_alt: "",
              img_credit: "",
              link_story: "",
            },
          ],
        },
        ...cards,
      ]);
    }
    else {
      setCards([
        ...cards,
        {
          sub_card: [
            {
              type: "Image",
              title: "",
              attributes: "",
              actionUrl: "",
              description: "",
              isExternal: false,
              noFollow: false,
              question: "",
              question_attributes: "",
              answer: "",
              answer_attributes: "",
              summary: "",
              images: "",
              img_caption: "",
              img_attribution: "",
              link_story: "",
            },
            {
              type: "Text",
              title: "",
              attributes: "",
              actionUrl: "",
              description: "",
              isExternal: false,
              noFollow: false,
              question: "",
              question_attributes: "",
              answer: "",
              answer_attributes: "",
              summary: "",
              images: "",
              img_caption: "",
              img_attribution: "",
              img_alt: "",
              img_credit: "",
              link_story: "",
            },
          ],
        },
      ]);
    }

  };
  const handleDelete = (cardIndex, subCardIndex, cardType) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        if (cardType === "subcard") {
          removeSubCard(cardIndex, subCardIndex);
          if (cards[cardIndex]?.sub_card?.length == 0) {
            // delete card if last sub card is deleted.
            removeCard(cardIndex);
          }
        } else if (cardType === "card") {
          removeCard(cardIndex);
        }
      }
    });
  };

  // Function to remove a card
  const removeCard = (index) => {
    const updatedCards = [...cards];
    updatedCards.splice(index, 1);
    setCards(updatedCards);
    setValue("cards", updatedCards);
  };

  // Function to add a new sub-card to a specific card
  const addSubCard = (e, type, cardIndex, top = 0) => {
    e?.preventDefault();
    setToolbarIndex(null);
    const updatedCards = [...cards];
    if (top) {
      updatedCards[cardIndex].sub_card.unshift({
        type: type,
        title: "",
        attributes: "",
        actionUrl: "",
        description: "",
        isExternal: false,
        noFollow: false,
        question: "",
        question_attributes: "",
        answer: "",
        answer_attributes: "",
        summary: "",
        images: "",
        img_caption: "",
        img_attribution: "",
        link_story: "",
      });
    }
    else {
      updatedCards[cardIndex].sub_card.push({
        type: type,
        title: "",
        attributes: "",
        actionUrl: "",
        description: "",
        isExternal: false,
        noFollow: false,
        question: "",
        question_attributes: "",
        answer: "",
        answer_attributes: "",
        summary: "",
        images: "",
        img_caption: "",
        img_attribution: "",
        link_story: "",
      });
    }

    setCards(updatedCards);
    setValue("cards", updatedCards);
  };

  // Function to remove a sub-card from a specific card
  const removeSubCard = (cardIndex, subCardIndex) => {
    const updatedCards = [...cards];
    updatedCards[cardIndex].sub_card.splice(subCardIndex, 1);
    setCards(updatedCards);
    setValue("cards", updatedCards);
    // setForm("cards", updatedCards);
  };
  const [toolbarIndex, setToolbarIndex] = useState(null);
  const [topToolbar, setTopToolbar] = useState(false);
  const handleToolbar = (index) => {
    setToolbarIndex(index);
  };

  const HandleImageDelete = async (cardIndex, subCardIndex, imgId) => {
    const response = await DeleteApi(`${apiEndpoints.uploadById(imgId)}`);
    if (response.status === 204) {
      handleSubCardChange(cardIndex, subCardIndex, "images", "");
    }
  };

  //Drag and Drop functionality starts here.
  const moveCard = (fromIndex, toIndex) => {
    const updatedCards = [...cards];
    const [movedCard] = updatedCards.splice(fromIndex, 1);
    updatedCards.splice(toIndex, 0, movedCard);
    setCards(updatedCards);
    setValue("cards", updatedCards);
  };

  const moveSubCard = (cardIndex, subCardIndex, direction) => {
    const updatedCards = [...cards];
    const currentSubCard = updatedCards[cardIndex].sub_card[subCardIndex];
    const targetSubCardIndex =
      direction === "up" ? subCardIndex - 1 : subCardIndex + 1;

    if (
      targetSubCardIndex >= 0 &&
      targetSubCardIndex < updatedCards[cardIndex].sub_card.length
    ) {
      updatedCards[cardIndex].sub_card[subCardIndex] =
        updatedCards[cardIndex].sub_card[targetSubCardIndex];
      updatedCards[cardIndex].sub_card[targetSubCardIndex] = currentSubCard;
      setCards(updatedCards);
      setValue("cards", updatedCards);
    }
  };

  useEffect(() => {
    setValue("cards", cards);
    setCards(cards);
  }, [cards]);

  return (
    <div className="container ms-0 ps-0">
      <form>
        {/* Render cards and sub-cards */}
        {cards?.length > 0 &&
          cards.map((card, cardIndex) => {
            return (
              <div key={cardIndex} className="p-relative">
                {/* Render card content */}
                <div>
                  <div className="main-editor-bx mt-4 col-lg-8 row ">
                    {topToolbar && isShowHeader && toolbarIndex === cardIndex ? (
                      <div
                        className="col-lg-1 px-0 col-sm-12 add-icon"
                        onClick={(e) => {
                          if (!editEnabled) return
                          handleToolbar(cardIndex);
                          setIsShowHeader(false);
                          setTopToolbar(false)
                        }}
                      >
                        <svg
                          className="rotate"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="var(--mono-4)"
                          width="32"
                          height="32"
                          viewBox="0 0 24 24"
                        >
                          <path d="M19.6 16.26a9 9 0 0 1-3.18 3.18 8.64 8.64 0 0 1-8.72 0 8.81 8.81 0 0 1-3.18-3.18 8.42 8.42 0 0 1-1.18-4.36 8.4 8.4 0 0 1 1.18-4.36A8.66 8.66 0 0 1 7.7 4.36a8.4 8.4 0 0 1 4.36-1.18 8.42 8.42 0 0 1 4.36 1.18 8.81 8.81 0 0 1 3.18 3.18 8.64 8.64 0 0 1 0 8.72zm-1-8.18A7.64 7.64 0 0 0 12 4.3a7.39 7.39 0 0 0-3.8 1 7.59 7.59 0 0 0-2.72 2.8 7.45 7.45 0 0 0-1 3.81 7.36 7.36 0 0 0 1 3.8 7.59 7.59 0 0 0 2.78 2.76 7.46 7.46 0 0 0 3.82 1 7.35 7.35 0 0 0 3.79-1 7.51 7.51 0 0 0 2.76-2.78 7.61 7.61 0 0 0 0-7.61zm-2.07 4a.41.41 0 0 1-.13.3.4.4 0 0 1-.29.12h-3.42V16a.41.41 0 0 1-.12.3.38.38 0 0 1-.3.13h-.42a.38.38 0 0 1-.3-.13.42.42 0 0 1-.13-.3v-3.47H8a.41.41 0 0 1-.3-.12.44.44 0 0 1-.12-.3v-.42a.41.41 0 0 1 .12-.3.38.38 0 0 1 .3-.13h3.44V7.82a.42.42 0 0 1 .13-.3.41.41 0 0 1 .3-.12h.42a.41.41 0 0 1 .42.42v3.44h3.45a.37.37 0 0 1 .29.13.38.38 0 0 1 .13.3z"></path>
                        </svg>
                      </div>
                    ) : (
                      <div
                        className="col-lg-1 px-0 col-sm-12 add-icon"
                        onClick={(e) => {
                          if (!editEnabled) return
                          handleToolbar(cardIndex);
                          setIsShowHeader(true);
                          setTopToolbar(true)
                        }}
                      >
                        <svg
                          className="rotate-original"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="var(--mono-4)"
                          width="32"
                          height="32"
                          viewBox="0 0 24 24"
                        >
                          <path d="M19.6 16.26a9 9 0 0 1-3.18 3.18 8.64 8.64 0 0 1-8.72 0 8.81 8.81 0 0 1-3.18-3.18 8.42 8.42 0 0 1-1.18-4.36 8.4 8.4 0 0 1 1.18-4.36A8.66 8.66 0 0 1 7.7 4.36a8.4 8.4 0 0 1 4.36-1.18 8.42 8.42 0 0 1 4.36 1.18 8.81 8.81 0 0 1 3.18 3.18 8.64 8.64 0 0 1 0 8.72zm-1-8.18A7.64 7.64 0 0 0 12 4.3a7.39 7.39 0 0 0-3.8 1 7.59 7.59 0 0 0-2.72 2.8 7.45 7.45 0 0 0-1 3.81 7.36 7.36 0 0 0 1 3.8 7.59 7.59 0 0 0 2.78 2.76 7.46 7.46 0 0 0 3.82 1 7.35 7.35 0 0 0 3.79-1 7.51 7.51 0 0 0 2.76-2.78 7.61 7.61 0 0 0 0-7.61zm-2.07 4a.41.41 0 0 1-.13.3.4.4 0 0 1-.29.12h-3.42V16a.41.41 0 0 1-.12.3.38.38 0 0 1-.3.13h-.42a.38.38 0 0 1-.3-.13.42.42 0 0 1-.13-.3v-3.47H8a.41.41 0 0 1-.3-.12.44.44 0 0 1-.12-.3v-.42a.41.41 0 0 1 .12-.3.38.38 0 0 1 .3-.13h3.44V7.82a.42.42 0 0 1 .13-.3.41.41 0 0 1 .3-.12h.42a.41.41 0 0 1 .42.42v3.44h3.45a.37.37 0 0 1 .29.13.38.38 0 0 1 .13.3z"></path>
                        </svg>
                      </div>
                    )}

                    <div
                      className={
                        topToolbar && isShowHeader && toolbarIndex === cardIndex
                          ? "col-lg-8 px-0 col-sm-12 editor-bx "
                          : "col-lg-8 px-0 col-sm-12 editor-bx  invisible"
                      }
                    >
                      <button
                        data-tooltip-id="my-tooltip-1"
                        // onClick={(e) => handleAddEntry(e, "Text")}
                        onClick={(e) => addSubCard(e, "Text", cardIndex, 1)}
                      >
                        <svg
                          data-name="Layer 1"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="#5f6978"
                          viewBox="0 0 24 24"
                        >
                          <path d="M7.01 11.99h1.01V5.85H11v-.86H4.04v.86h2.97v6.14zM9.64 9.86h10.31v1H9.64zM4.96 12.86h15v1h-15zM4.96 15.86h15v1h-15zM4.96 18.86h15v1h-15z"></path>
                        </svg>
                      </button>
                      <ReactTooltip
                        id="my-tooltip-1"
                        place="bottom"
                        content="Text"
                      />
                      <button
                        data-tooltip-id="my-tooltip-4"
                        onClick={(e) => addSubCard(e, "Quote", cardIndex, 1)}
                      >
                        <svg
                          width="24"
                          height="24"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fillRule="evenodd"
                            d="M8.56 21v-4.32l.44-.05c.13 0 3.13-.41 2.91-4.66H6.78V3h10.31v.48s.16 3.94.08 8.21S14 20.63 9.1 21zm1-3.48v2.39c4-.59 6.55-4.64 6.61-8.24.06-3.37 0-6.51-.06-7.68H7.8v7h5.05l.05.44c.45 4.57-2.23 5.78-3.33 6.09z"
                          ></path>
                        </svg>
                      </button>
                      <ReactTooltip
                        id="my-tooltip-4"
                        place="bottom"
                        content="Quote"
                      />
                      <button
                        data-tooltip-id="my-tooltip-2"
                        onClick={(e) => addSubCard(e, "Blockquote", cardIndex, 1)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="24"
                          height="24"
                          fill="#5f6978"
                        >
                          <path
                            d="M12.23 3.77H3V12h4.6c.2 3.89-2.49 4.25-2.6 4.26l-.41.05v3.95h.49c4.39-.3 7.18-4.64 7.24-8.48s-.07-7.47-.07-7.5zm-.81 7.93c-.06 3.29-2.33 7-5.92 7.53v-2.18c1-.29 3.38-1.41 3-5.58v-.41H3.91V4.68h7.45c.03 1.06.11 3.94.06 7.02zm9.49-7.49v-.44H13.7v.91H20c0 1.06.11 3.94.05 7-.06 3.29-2.32 7-5.92 7.53v-2.16c1-.29 3.38-1.41 3-5.58v-.41H13.7V12h2.56c.19 3.89-2.49 4.25-2.61 4.26l-.4.05v3.95h.48c4.39-.3 7.18-4.64 7.25-8.48s-.07-7.47-.07-7.5"
                            fillRule="evenodd"
                          ></path>
                        </svg>
                      </button>
                      <ReactTooltip
                        id="my-tooltip-2"
                        place="bottom"
                        content="Blockquote"
                      />
                      <button
                        data-tooltip-id="my-tooltip-3"
                        // onClick={() => handleAddEntry(e, "CTA")}
                        onClick={(e) => addSubCard(e, "CTA", cardIndex, 1)}
                      >
                        <svg
                          viewBox="0 0 24 24"
                          width="24"
                          height="24"
                          fill="#5f6978"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="m14.49 13.04c-0.29 0.09-0.5 0.32-0.56 0.61-0.04 0.17 0.14 4.87 0.21 5.59 0.04 0.31 0.07 0.37 0.29 0.52 0.39 0.27 0.75 0.24 1.37-0.13 0.59-0.36 0.47-0.44 1.19 0.79 0.28 0.48 0.58 0.92 0.68 0.98 0.12 0.08 0.32 0.13 0.53 0.13 0.28 0 0.43-0.06 0.85-0.3 0.64-0.38 0.87-0.59 0.95-0.89 0.09-0.34 0.02-0.54-0.56-1.54-0.27-0.47-0.5-0.88-0.51-0.91s0.21-0.21 0.49-0.41c0.42-0.29 0.52-0.39 0.55-0.58 0.08-0.38-0.01-0.7-0.25-0.94-0.13-0.12-1.17-0.83-2.33-1.59-2.26-1.48-2.34-1.52-2.9-1.33zm2.51 2.21c1.09 0.72 1.98 1.33 1.98 1.35s-0.3 0.25-0.67 0.51c-0.64 0.44-0.67 0.48-0.58 0.61 0.32 0.51 1.32 2.29 1.32 2.34 0 0.06-0.76 0.55-0.85 0.55-0.01 0-0.34-0.57-0.75-1.27-0.4-0.7-0.73-1.28-0.74-1.29-0.01-0.02-0.37 0.18-0.8 0.43-0.44 0.25-0.8 0.44-0.82 0.43-0.02-0.02-0.24-4.54-0.24-4.9 0-0.08 0.03-0.11 0.09-0.09 0.04 0.02 0.97 0.62 2.06 1.33zm-10.97-3.72v0.47h6.28 6.27v-0.47-0.48h-6.27-6.28v0.48zm-2.59-6.44c-0.69 0.16-1.34 0.69-1.66 1.35l-0.19 0.37-0.02 4.59c-0.01 4.49-0.01 4.59 0.12 4.96 0.27 0.7 0.85 1.25 1.56 1.47 0.4 0.13 0.62 0.14 4.59 0.14h4.16v-0.48-0.47h-4.16-4.15l-0.37-0.19c-0.45-0.23-0.72-0.59-0.81-1.09-0.09-0.49-0.09-8 0-8.49 0.09-0.5 0.36-0.86 0.81-1.09l0.35-0.19h8.33 8.33l0.35 0.19c0.45 0.23 0.72 0.59 0.81 1.09 0.04 0.21 0.07 1.93 0.07 4.25v3.89h0.44 0.44l-0.01-4.29-0.02-4.29-0.19-0.38c-0.25-0.51-0.67-0.91-1.2-1.17l-0.44-0.21s-8.44-0.01-8.44-0.01c-4.78 0-8.55 0.02-8.7 0.05z"
                            stroke="none"
                          ></path>
                        </svg>
                      </button>
                      <ReactTooltip
                        id="my-tooltip-3"
                        place="bottom"
                        content="CTA"
                      />
                      <button
                        data-tooltip-id="my-tooltip-5"
                        // onClick={() => handleAddEntry(e, "Big Fact")}
                        onClick={(e) => addSubCard(e, "Big Fact", cardIndex, 1)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="24"
                          height="24"
                          fill="currentColor"
                        >
                          <path
                            d="M18.56 3.12H5.23a2.4 2.4 0 0 0-2.42 2.4v13.2a2.41 2.41 0 0 0 2.42 2.4h13.33a2.41 2.41 0 0 0 2.44-2.4V5.52a2.4 2.4 0 0 0-2.44-2.4zM20 18.72a1.38 1.38 0 0 1-1.39 1.38H5.23a1.38 1.38 0 0 1-1.39-1.38V5.52a1.38 1.38 0 0 1 1.39-1.38h13.33A1.38 1.38 0 0 1 20 5.52z"
                            fillRule="evenodd"
                          ></path>
                          <path
                            d="M12.56 16.57a.55.55 0 0 1-.29.08c-.08 0-.29 0-.29-.39v-.18l.79-5.49-.19-.09-2.09.3-.06.35.77.35-.38 2.67c-.17 1.19-.28 2-.35 2.56a.53.53 0 0 0 .22.46 1.14 1.14 0 0 0 .69.17 1.64 1.64 0 0 0 .71-.15 2.36 2.36 0 0 0 .55-.34c.13-.12.23-.22.28-.22l-.12-.27-.27.19m.29-8.94a.94.94 0 0 0-.9 0 1 1 0 0 0-.35.34 1 1 0 0 0-.12.48.65.65 0 0 0 .1.36.86.86 0 0 0 .28.29.79.79 0 0 0 .37.1 1.12 1.12 0 0 0 .53-.13.84.84 0 0 0 .34-.36 1.06 1.06 0 0 0 .12-.5.64.64 0 0 0-.1-.35.62.62 0 0 0-.27-.26"
                            fillRule="evenodd"
                          ></path>
                        </svg>
                      </button>
                      <ReactTooltip
                        id="my-tooltip-5"
                        place="bottom"
                        content="Big Fact"
                      />
                      <button
                        data-tooltip-id="my-tooltip-6"
                        onClick={(e) => addSubCard(e, "Image", cardIndex, 1)}
                      >
                        <svg width="24" height="24" fill="currentColor">
                          <path
                            d="M18.64 3.13H5.42A2.39 2.39 0 0 0 3 5.52v13.21a2.4 2.4 0 0 0 2.39 2.4h13.25a2.4 2.4 0 0 0 2.36-2.4V5.52a2.39 2.39 0 0 0-2.39-2.39M4.75 19.94l3.41-4.39 2.16 1.76.64-.78L8 14.12l-3.9 5a1.76 1.76 0 0 1-.06-.4V5.52a1.38 1.38 0 0 1 1.38-1.38h13.22A1.38 1.38 0 0 1 20 5.52v9.13l-3.41-3.8L9 20.12H5.42a1.4 1.4 0 0 1-.67-.18m13.89.18h-8.36l6.37-7.72L20 16.17v2.56a1.38 1.38 0 0 1-1.38 1.39"
                            fill="none"
                          ></path>
                          <path
                            d="M18.64 3.13H5.42A2.39 2.39 0 0 0 3 5.52v13.21a2.4 2.4 0 0 0 2.39 2.4h13.25a2.4 2.4 0 0 0 2.36-2.4V5.52a2.39 2.39 0 0 0-2.39-2.39M4.75 19.94l3.41-4.39 2.16 1.76.64-.78L8 14.12l-3.9 5a1.76 1.76 0 0 1-.06-.4V5.52a1.38 1.38 0 0 1 1.38-1.38h13.22A1.38 1.38 0 0 1 20 5.52v9.13l-3.41-3.8L9 20.12H5.42a1.4 1.4 0 0 1-.67-.18m13.89.18h-8.36l6.37-7.72L20 16.17v2.56a1.38 1.38 0 0 1-1.38 1.39"
                            fillRule="evenodd"
                          ></path>
                        </svg>
                      </button>
                      <ReactTooltip
                        id="my-tooltip-6"
                        place="bottom"
                        content="Image"
                      />
                    </div>
                  </div>
                </div>
                {/* Render sub-cards */}
                {card?.sub_card?.length > 0 &&
                  card?.sub_card.map((subCard, subCardIndex) => (
                    <div key={subCardIndex}>
                      {/* Render sub-card fields */}
                      <div className="row">
                        <div className="col-lg-2 col-sm-12"></div>
                        <div className="col-lg-8 col-sm-12">
                          <div className="main-bx" key={subCardIndex}>
                            <div className="bx1">
                              <button
                                data-tooltip-id="my-tooltip-0"
                                className="delete-btn ms-1"
                                onClick={
                                  (e) => {
                                    if (!editEnabled) return
                                    e?.preventDefault();
                                    handleDelete(
                                      cardIndex,
                                      subCardIndex,
                                      "subcard"
                                    );
                                    if (
                                      subCard.type.toLowerCase() === "image" &&
                                      subCard?.file_Id &&
                                      subCard?.file_Id !== ""
                                    ) {
                                      HandleImageDelete(
                                        cardIndex,
                                        subCardIndex,
                                        subCard?.file_Id
                                      );
                                    }
                                  }
                                  // removeSubCard(cardIndex, subCardIndex)
                                }
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="#5f6978"
                                  viewBox="0 0 24 24"
                                  width="24"
                                  height="24"
                                >
                                  <path d="M4.19 5A.85.85 0 0 1 5 4.1h4l.32-.67a.76.76 0 0 1 .28-.33 1 1 0 0 1 .46-.1h4a.93.93 0 0 1 .46.12.76.76 0 0 1 .31.33l.32.67h3.95a.85.85 0 0 1 .84.85v1a.41.41 0 0 1-.42.42H4.61a.44.44 0 0 1-.3-.12.41.41 0 0 1-.12-.3zm13.89 14.39a1.61 1.61 0 0 1-.53 1.13 1.68 1.68 0 0 1-1.16.46H7.74a1.68 1.68 0 0 1-1.16-.46 1.61 1.61 0 0 1-.53-1.13l-.7-11.46a.47.47 0 0 1 .12-.31.39.39 0 0 1 .3-.14h12.59a.36.36 0 0 1 .3.14.47.47 0 0 1 .12.31z"></path>
                                </svg>
                              </button>
                              <ReactTooltip
                                id="my-tooltip-0"
                                place="bottom"
                                content="Delete"
                              />
                              <div
                                className={`d-flex up-down-btn justify-content-between ms-1 mt-4`}
                              >
                                <span
                                  role="button"
                                  onClick={() => {
                                    if (editEnabled)
                                      moveSubCard(cardIndex, subCardIndex, "up")
                                  }
                                  }
                                  disabled={subCardIndex === 0 || !editEnabled}
                                >
                                  <i className="fa  fa-arrow-circle-up"></i>
                                </span>
                                <span
                                  role="button"
                                  onClick={() =>
                                    moveSubCard(cardIndex, subCardIndex, "down")
                                  }
                                  disabled={
                                    subCardIndex === card.sub_card.length - 1 || !editEnabled
                                  }
                                >
                                  <i className="fa  fa-arrow-circle-down"></i>
                                </span>
                              </div>
                            </div>
                            <div className="bx2">
                              {subCard.type.toLowerCase() === "text" && (
                                <div className="width-100">
                                  <label>
                                    <svg
                                      data-name="Layer 1"
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      fill="#5f6978"
                                      viewBox="0 0 24 24"
                                    >
                                      <path d="M7.01 11.99h1.01V5.85H11v-.86H4.04v.86h2.97v6.14zM9.64 9.86h10.31v1H9.64zM4.96 12.86h15v1h-15zM4.96 15.86h15v1h-15zM4.96 18.86h15v1h-15z"></path>
                                    </svg>
                                    {subCard?.type}{" "}
                                  </label>
                                  <QuillEditor
                                    subCard={subCard}
                                    handleSubCardChange={handleSubCardChange}
                                    cardIndex={cardIndex}
                                    subCardIndex={subCardIndex}
                                    cards={cards}
                                    onAutoSaveStory={onAutoSaveStory}
                                    editEnabled={editEnabled}
                                  />

                                  <div className={`text-end`}>{`${countWordsAndChars(subCard?.title)
                                    ?.wordCount
                                    } words / ${countWordsAndChars(subCard?.title)
                                      ?.characters
                                    } characters`}</div>
                                </div>
                              )}
                              {subCard.type.toLowerCase() === "blockquote" && (
                                <>
                                  <Form.Label className={`me-1`}>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                      width="24"
                                      height="24"
                                      fill="#5f6978"
                                    >
                                      <path
                                        d="M12.23 3.77H3V12h4.6c.2 3.89-2.49 4.25-2.6 4.26l-.41.05v3.95h.49c4.39-.3 7.18-4.64 7.24-8.48s-.07-7.47-.07-7.5zm-.81 7.93c-.06 3.29-2.33 7-5.92 7.53v-2.18c1-.29 3.38-1.41 3-5.58v-.41H3.91V4.68h7.45c.03 1.06.11 3.94.06 7.02zm9.49-7.49v-.44H13.7v.91H20c0 1.06.11 3.94.05 7-.06 3.29-2.32 7-5.92 7.53v-2.16c1-.29 3.38-1.41 3-5.58v-.41H13.7V12h2.56c.19 3.89-2.49 4.25-2.61 4.26l-.4.05v3.95h.48c4.39-.3 7.18-4.64 7.25-8.48s-.07-7.47-.07-7.5"
                                        fillRule="evenodd"
                                      ></path>
                                    </svg>
                                    {subCard?.type}
                                  </Form.Label>
                                  <br></br>
                                  <input
                                    type="text"
                                    placeholder="Enter title"
                                    // {...register(
                                    //   `cards[${cardIndex}].sub_card[${subCardIndex}].title`
                                    // )}
                                    value={subCard.title}
                                    onChange={(e) =>
                                      handleSubCardChange(
                                        cardIndex,
                                        subCardIndex,
                                        "title",
                                        e.target.value
                                      )
                                    }
                                    onBlur={(e) => {
                                      e?.preventDefault();
                                      onAutoSaveStory();
                                    }}
                                  />
                                  <input
                                    type="text"
                                    placeholder="Enter attributes"
                                    // {...register(
                                    //   `cards[${cardIndex}].sub_card[${subCardIndex}].attributes`
                                    // )}
                                    value={subCard.attributes}
                                    onChange={(e) =>
                                      handleSubCardChange(
                                        cardIndex,
                                        subCardIndex,
                                        "attributes",
                                        e.target.value
                                      )
                                    }
                                    onBlur={(e) => {
                                      e?.preventDefault();
                                      onAutoSaveStory();
                                    }}
                                  />
                                </>
                              )}
                              {subCard.type.toLowerCase() === "quote" && (
                                <>
                                  <Form.Label className={`me-1`}>
                                    <svg
                                      width="24"
                                      height="24"
                                      fill="currentColor"
                                      viewBox="0 0 24 24"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        d="M8.56 21v-4.32l.44-.05c.13 0 3.13-.41 2.91-4.66H6.78V3h10.31v.48s.16 3.94.08 8.21S14 20.63 9.1 21zm1-3.48v2.39c4-.59 6.55-4.64 6.61-8.24.06-3.37 0-6.51-.06-7.68H7.8v7h5.05l.05.44c.45 4.57-2.23 5.78-3.33 6.09z"
                                      ></path>
                                    </svg>
                                    {subCard?.type}
                                  </Form.Label>
                                  <br></br>
                                  <input
                                    type="text"
                                    placeholder="Enter Title"
                                    // {...register(
                                    //   `cards[${cardIndex}].sub_card[${subCardIndex}].title`
                                    // )}
                                    value={subCard.title}
                                    onChange={(e) =>
                                      handleSubCardChange(
                                        cardIndex,
                                        subCardIndex,
                                        "title",
                                        e.target.value
                                      )
                                    }
                                    onBlur={(e) => {
                                      e?.preventDefault();
                                      onAutoSaveStory();
                                    }}
                                  />
                                  <input
                                    type="text"
                                    placeholder="Enter Attribution"
                                    // {...register(
                                    //   `cards[${cardIndex}].sub_card[${subCardIndex}].attributes`
                                    // )}
                                    value={subCard.attributes}
                                    onChange={(e) =>
                                      handleSubCardChange(
                                        cardIndex,
                                        subCardIndex,
                                        "attributes",
                                        e.target.value
                                      )
                                    }
                                    onBlur={(e) => {
                                      e?.preventDefault();
                                      onAutoSaveStory();
                                    }}
                                  />
                                </>
                              )}
                              {subCard.type.toLowerCase() === "cta" && (
                                <>
                                  <Form.Label className={`me-1`}>
                                    <svg
                                      viewBox="0 0 24 24"
                                      width="24"
                                      height="24"
                                      fill="#5f6978"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="m14.49 13.04c-0.29 0.09-0.5 0.32-0.56 0.61-0.04 0.17 0.14 4.87 0.21 5.59 0.04 0.31 0.07 0.37 0.29 0.52 0.39 0.27 0.75 0.24 1.37-0.13 0.59-0.36 0.47-0.44 1.19 0.79 0.28 0.48 0.58 0.92 0.68 0.98 0.12 0.08 0.32 0.13 0.53 0.13 0.28 0 0.43-0.06 0.85-0.3 0.64-0.38 0.87-0.59 0.95-0.89 0.09-0.34 0.02-0.54-0.56-1.54-0.27-0.47-0.5-0.88-0.51-0.91s0.21-0.21 0.49-0.41c0.42-0.29 0.52-0.39 0.55-0.58 0.08-0.38-0.01-0.7-0.25-0.94-0.13-0.12-1.17-0.83-2.33-1.59-2.26-1.48-2.34-1.52-2.9-1.33zm2.51 2.21c1.09 0.72 1.98 1.33 1.98 1.35s-0.3 0.25-0.67 0.51c-0.64 0.44-0.67 0.48-0.58 0.61 0.32 0.51 1.32 2.29 1.32 2.34 0 0.06-0.76 0.55-0.85 0.55-0.01 0-0.34-0.57-0.75-1.27-0.4-0.7-0.73-1.28-0.74-1.29-0.01-0.02-0.37 0.18-0.8 0.43-0.44 0.25-0.8 0.44-0.82 0.43-0.02-0.02-0.24-4.54-0.24-4.9 0-0.08 0.03-0.11 0.09-0.09 0.04 0.02 0.97 0.62 2.06 1.33zm-10.97-3.72v0.47h6.28 6.27v-0.47-0.48h-6.27-6.28v0.48zm-2.59-6.44c-0.69 0.16-1.34 0.69-1.66 1.35l-0.19 0.37-0.02 4.59c-0.01 4.49-0.01 4.59 0.12 4.96 0.27 0.7 0.85 1.25 1.56 1.47 0.4 0.13 0.62 0.14 4.59 0.14h4.16v-0.48-0.47h-4.16-4.15l-0.37-0.19c-0.45-0.23-0.72-0.59-0.81-1.09-0.09-0.49-0.09-8 0-8.49 0.09-0.5 0.36-0.86 0.81-1.09l0.35-0.19h8.33 8.33l0.35 0.19c0.45 0.23 0.72 0.59 0.81 1.09 0.04 0.21 0.07 1.93 0.07 4.25v3.89h0.44 0.44l-0.01-4.29-0.02-4.29-0.19-0.38c-0.25-0.51-0.67-0.91-1.2-1.17l-0.44-0.21s-8.44-0.01-8.44-0.01c-4.78 0-8.55 0.02-8.7 0.05z"
                                        stroke="none"
                                      ></path>
                                    </svg>
                                    {subCard?.type}
                                  </Form.Label>
                                  <div className="row cta">
                                    <div className="col-md-4">
                                      <input
                                        type="text"
                                        placeholder="Enter title"
                                        {...register(
                                          `cards[${cardIndex}].sub_card[${subCardIndex}].title`,
                                          {
                                            required: true,
                                          }
                                        )}
                                        value={subCard.title}
                                        onChange={(e) =>
                                          handleSubCardChange(
                                            cardIndex,
                                            subCardIndex,
                                            "title",
                                            e.target.value
                                          )
                                        }
                                        onBlur={(e) => {
                                          e?.preventDefault();
                                          onAutoSaveStory();
                                        }}
                                      />
                                      <label>
                                        {errors?.cards?.length > 0 &&
                                          errors?.cards[cardIndex]?.sub_card[
                                            subCardIndex
                                          ]?.title && (
                                            <span className="text-danger">
                                              Title is required
                                            </span>
                                          )}
                                      </label>
                                    </div>
                                    <div className="col-md-4">
                                      <input
                                        type="text"
                                        placeholder="Enter action URL"
                                        name="actionUrl"
                                        {...register(
                                          `cards[${cardIndex}].sub_card[${subCardIndex}].actionUrl`,
                                          {
                                            required: true,
                                          }
                                        )}
                                        value={subCard.actionUrl}
                                        onChange={(e) =>
                                          handleSubCardChange(
                                            cardIndex,
                                            subCardIndex,
                                            "actionUrl",
                                            e.target.value
                                          )
                                        }
                                        onBlur={(e) => {
                                          e?.preventDefault();
                                          onAutoSaveStory();
                                        }}
                                      />
                                      <label>
                                        {errors?.cards?.length > 0 &&
                                          errors?.cards[cardIndex]?.sub_card[
                                            subCardIndex
                                          ]?.actionUrl && (
                                            <span className="text-danger">
                                              Valid url is required
                                            </span>
                                          )}
                                      </label>
                                    </div>
                                    <div className="col-md-4">
                                      <label>
                                        <div className="check-bx">
                                          <input
                                            type="checkbox"
                                            name="isExternal"
                                            // {...register(
                                            //   `cards[${cardIndex}].sub_card[${subCardIndex}].isExternal`
                                            // )}
                                            checked={subCard.isExternal}
                                            onChange={(e) =>
                                              handleSubCardChange(
                                                cardIndex,
                                                subCardIndex,
                                                "isExternal",
                                                e.target.checked
                                              )
                                            }
                                            onBlur={(e) => {
                                              e?.preventDefault();
                                              onAutoSaveStory();
                                            }}
                                          />
                                          Is External
                                        </div>
                                      </label>
                                      <label>
                                        <div className="check-bx">
                                          <input
                                            type="checkbox"
                                            // {...register(
                                            //   `cards[${cardIndex}].sub_card[${subCardIndex}].noFollow`
                                            // )}
                                            checked={subCard.noFollow}
                                            onChange={(e) =>
                                              handleSubCardChange(
                                                cardIndex,
                                                subCardIndex,
                                                "noFollow",
                                                e.target.checked
                                              )
                                            }
                                            onBlur={(e) => {
                                              e?.preventDefault();
                                              onAutoSaveStory();
                                            }}
                                          />
                                          No Follow
                                        </div>
                                      </label>
                                    </div>
                                  </div>
                                </>
                              )}
                              {subCard.type.toLowerCase() === "big fact" && (
                                <div className="width-100">
                                  <Form.Label>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                      width="24"
                                      height="24"
                                      fill="currentColor"
                                    >
                                      <path
                                        d="M18.56 3.12H5.23a2.4 2.4 0 0 0-2.42 2.4v13.2a2.41 2.41 0 0 0 2.42 2.4h13.33a2.41 2.41 0 0 0 2.44-2.4V5.52a2.4 2.4 0 0 0-2.44-2.4zM20 18.72a1.38 1.38 0 0 1-1.39 1.38H5.23a1.38 1.38 0 0 1-1.39-1.38V5.52a1.38 1.38 0 0 1 1.39-1.38h13.33A1.38 1.38 0 0 1 20 5.52z"
                                        fillRule="evenodd"
                                      ></path>
                                      <path
                                        d="M12.56 16.57a.55.55 0 0 1-.29.08c-.08 0-.29 0-.29-.39v-.18l.79-5.49-.19-.09-2.09.3-.06.35.77.35-.38 2.67c-.17 1.19-.28 2-.35 2.56a.53.53 0 0 0 .22.46 1.14 1.14 0 0 0 .69.17 1.64 1.64 0 0 0 .71-.15 2.36 2.36 0 0 0 .55-.34c.13-.12.23-.22.28-.22l-.12-.27-.27.19m.29-8.94a.94.94 0 0 0-.9 0 1 1 0 0 0-.35.34 1 1 0 0 0-.12.48.65.65 0 0 0 .1.36.86.86 0 0 0 .28.29.79.79 0 0 0 .37.1 1.12 1.12 0 0 0 .53-.13.84.84 0 0 0 .34-.36 1.06 1.06 0 0 0 .12-.5.64.64 0 0 0-.1-.35.62.62 0 0 0-.27-.26"
                                        fillRule="evenodd"
                                      ></path>
                                    </svg>
                                    {subCard?.type}
                                  </Form.Label>
                                  <Form.Control
                                    // className="form-control border "
                                    as="textarea"
                                    name="title"
                                    placeholder="Enter Title"
                                    maxLength={200}
                                    // {...register(
                                    //   `cards[${cardIndex}].sub_card[${subCardIndex}].title`
                                    // )}
                                    value={subCard.title}
                                    onChange={(e) =>
                                      handleSubCardChange(
                                        cardIndex,
                                        subCardIndex,
                                        "title",
                                        e.target.value
                                      )
                                    }
                                    onBlur={(e) => {
                                      e?.preventDefault();
                                      onAutoSaveStory();
                                    }}
                                  />
                                  <Form.Label>Description</Form.Label>
                                  <Form.Control
                                    // className="form-control border "
                                    as="textarea"
                                    name="description"
                                    placeholder="Enter description"
                                    maxLength={200}
                                    // {...register(
                                    //   `cards[${cardIndex}].sub_card[${subCardIndex}].description`
                                    // )}
                                    value={subCard.description}
                                    onChange={(e) =>
                                      handleSubCardChange(
                                        cardIndex,
                                        subCardIndex,
                                        "description",
                                        e.target.value
                                      )
                                    }
                                    onBlur={(e) => {
                                      e?.preventDefault();
                                      onAutoSaveStory();
                                    }}
                                  />
                                </div>
                              )}
                              {subCard.type.toLowerCase() === "blurb" && (
                                <div className="width-100">
                                  <Form.Label>
                                    <svg
                                      width="24"
                                      height="24"
                                      fill="currentColor"
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                    >
                                      <path d="M19.06,4.23a2.16,2.16,0,0,1,1.58.66,2.17,2.17,0,0,1,.67,1.59V16.6a2.29,2.29,0,0,1-2.25,2.25H14L9.6,22.16a.5.5,0,0,1-.26.07.35.35,0,0,1-.28-.13.41.41,0,0,1-.12-.3V18.85H5.56A2.13,2.13,0,0,1,4,18.18a2.13,2.13,0,0,1-.67-1.58V6.48A2.17,2.17,0,0,1,4,4.89a2.16,2.16,0,0,1,1.58-.66ZM20.19,16.6V6.48a1.08,1.08,0,0,0-.34-.79,1,1,0,0,0-.79-.34H5.56a1.06,1.06,0,0,0-.79.34,1.07,1.07,0,0,0-.33.79V16.6a1.07,1.07,0,0,0,.33.79,1.1,1.1,0,0,0,.79.34h4.5V20.4l3.55-2.67h5.45a1.16,1.16,0,0,0,1.13-1.13Z"></path>
                                      <rect
                                        x="7.31"
                                        y="8.98"
                                        width="10"
                                        height="1"
                                      ></rect>
                                      <rect
                                        x="7.31"
                                        y="12.98"
                                        width="10"
                                        height="1"
                                      ></rect>
                                    </svg>
                                    {subCard?.type}
                                  </Form.Label>
                                  <Form.Control
                                    // className="form-control border "
                                    as="textarea"
                                    name="title"
                                    placeholder="Enter Title"
                                    maxLength={200}
                                    // {...register(
                                    //   `cards[${cardIndex}].sub_card[${subCardIndex}].title`
                                    // )}
                                    value={subCard.title}
                                    onChange={(e) =>
                                      handleSubCardChange(
                                        cardIndex,
                                        subCardIndex,
                                        "title",
                                        e.target.value
                                      )
                                    }
                                    onBlur={(e) => {
                                      e?.preventDefault();
                                      onAutoSaveStory();
                                    }}
                                  />
                                </div>
                              )}
                              {subCard.type.toLowerCase() === "embed" && (
                                <div className="width-100">
                                  <label>
                                    <svg
                                      width="24"
                                      height="24"
                                      fill="currentColor"
                                      viewBox="0 0 24 24"
                                    >
                                      <path d="M14.07 4.5L8.9 18.98l-.05.12 1.08.4L15.1 5.02l.04-.12-1.07-.4z"></path>
                                      <path
                                        fillRule="evenodd"
                                        d="M2.5 11.88l4.92 4.87.8-.82-4.09-4.05 4-3.96.09-.09-.8-.82-4.92 4.87zm13.28-4.05l4.09 4.05-4 3.96-.09.09.79.82 4.93-4.87-4.93-4.87-.79.82z"
                                      ></path>
                                    </svg>
                                    {subCard?.type}
                                  </label>
                                  <Form.Control
                                    // className="form-control border "
                                    as="textarea"
                                    name="actionUrl"
                                    placeholder="Embed url or content"
                                    // onInput={(e) => autoResizeTextarea(e.target)}
                                    rows={2}
                                    // {...register(
                                    //   `cards[${cardIndex}].sub_card[${subCardIndex}].title`
                                    // )}
                                    value={subCard.actionUrl}
                                    onChange={(e) => {
                                      handleSubCardChange(
                                        cardIndex,
                                        subCardIndex,
                                        "actionUrl",
                                        e.target.value
                                      );
                                    }}
                                    onBlur={(e) => {
                                      e?.preventDefault();
                                      onAutoSaveStory();
                                    }}
                                  />
                                  {subCard?.actionUrl && (
                                    <LinkPreview link={subCard?.actionUrl} />
                                  )}
                                </div>
                              )}
                              {subCard.type.toLowerCase() === "question" && (
                                <div className="width-100">
                                  <Form.Label>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      fill="currentColor"
                                      viewBox="0 0 24 24"
                                    >
                                      <path d="M19 3a1.93 1.93 0 0 1 2 1.92v14.14A1.93 1.93 0 0 1 19 21H4.89A1.91 1.91 0 0 1 3 19.06V4.92A1.91 1.91 0 0 1 4.89 3zm.64 16.07V4.92a.62.62 0 0 0-.18-.46.63.63 0 0 0-.46-.19H4.89a.64.64 0 0 0-.46.19.62.62 0 0 0-.18.46v14.14a.62.62 0 0 0 .18.46.63.63 0 0 0 .46.18H19a.62.62 0 0 0 .64-.64zm-7.35-4.67a.48.48 0 0 0 .48-.48 1.52 1.52 0 0 1 .36-1 5.88 5.88 0 0 1 .93-.68 5.5 5.5 0 0 0 1.24-1 2.55 2.55 0 0 0 .56-1.69 2.81 2.81 0 0 0-1.12-2.33A4.36 4.36 0 0 0 12 6.36a4.6 4.6 0 0 0-2.21.49 5.43 5.43 0 0 0-1.52 1.32.43.43 0 0 0-.08.36.54.54 0 0 0 .2.33l.52.36a.46.46 0 0 0 .36.08.45.45 0 0 0 .29-.16 3.2 3.2 0 0 1 1-.89A3 3 0 0 1 12 8a2.58 2.58 0 0 1 1.72.52 1.34 1.34 0 0 1 .57 1.09 1.26 1.26 0 0 1-.41.92 4.24 4.24 0 0 1-.88.69 6.34 6.34 0 0 0-1.25 1 2.82 2.82 0 0 0-.56 1.77.48.48 0 0 0 .14.34.49.49 0 0 0 .34.14zm-.32 1a1.06 1.06 0 0 1 .8.33 1.16 1.16 0 0 1 0 1.6 1.06 1.06 0 0 1-.8.32 1.08 1.08 0 0 1-.81-.32 1.13 1.13 0 0 1 .84-1.97z"></path>
                                    </svg>
                                    Question
                                  </Form.Label>
                                  <Form.Control
                                    as="textarea"
                                    name="question"
                                    // placeholder="Enter Question"
                                    maxLength={200}
                                    // {...register(
                                    //   `cards[${cardIndex}].sub_card[${subCardIndex}].question`
                                    // )}
                                    value={subCard.question}
                                    onChange={(e) =>
                                      handleSubCardChange(
                                        cardIndex,
                                        subCardIndex,
                                        "question",
                                        e.target.value
                                      )
                                    }
                                    onBlur={(e) => {
                                      e?.preventDefault();
                                      onAutoSaveStory();
                                    }}
                                  />
                                  <Form.Label>Attribution</Form.Label>
                                  <Form.Control
                                    as="textarea"
                                    name="question_attributes"
                                    placeholder="Question Attribution"
                                    maxLength={200}
                                    // {...register(
                                    //   `cards[${cardIndex}].sub_card[${subCardIndex}].question_attributes`
                                    // )}
                                    value={subCard.question_attributes}
                                    onChange={(e) =>
                                      handleSubCardChange(
                                        cardIndex,
                                        subCardIndex,
                                        "question_attributes",
                                        e.target.value
                                      )
                                    }
                                    onBlur={(e) => {
                                      e?.preventDefault();
                                      onAutoSaveStory();
                                    }}
                                  />
                                </div>
                              )}
                              {subCard.type.toLowerCase() === "q qnd a" && (
                                <div className="width-100">
                                  <Form.Label>
                                    <svg
                                      data-name="Layer 1"
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                      width="24"
                                      height="24"
                                      fill="currentColor"
                                    >
                                      <path d="M9.19 6.72a1.3 1.3 0 0 1 1-.39 1.31 1.31 0 0 1 .94.33 1.1 1.1 0 0 1 .36.85.88.88 0 0 1-.13.51 3.37 3.37 0 0 1-.53.57 1.6 1.6 0 0 0-.37.47 1.33 1.33 0 0 0-.09.56h-.53a2.08 2.08 0 0 1 .1-.68 1.88 1.88 0 0 1 .45-.59l.24-.24a1.18 1.18 0 0 0 .18-.21.76.76 0 0 0-.06-.9.71.71 0 0 0-.58-.21.72.72 0 0 0-.7.36 1.49 1.49 0 0 0-.12.59h-.53a1.39 1.39 0 0 1 .37-1.02zm.64 4h.59v-.62h-.59z"></path>
                                      <path d="M3 17.43V4.82A1.83 1.83 0 0 1 4.83 3H15.6a1.83 1.83 0 0 1 1.83 1.82v7.41a1.83 1.83 0 0 1-1.83 1.83h-8a2.85 2.85 0 0 0-2 .84zM4.83 4a.87.87 0 0 0-.83.82v10.3l.9-.9a3.8 3.8 0 0 1 2.7-1.12h8a.87.87 0 0 0 .87-.87V4.82A.87.87 0 0 0 15.6 4z"></path>
                                      <path d="M21 20.62l-2.53-2.53a2.85 2.85 0 0 0-2-.84H9.59v-1h6.85a3.8 3.8 0 0 1 2.7 1.12l.9.9V9.21h1z"></path>
                                    </svg>
                                    Question
                                  </Form.Label>
                                  <Form.Control
                                    as="textarea"
                                    name="question"
                                    // placeholder="Enter Question"
                                    maxLength={200}
                                    {...register(
                                      `cards[${cardIndex}].sub_card[${subCardIndex}].question`,
                                      { required: true }
                                    )}
                                    value={subCard.question}
                                    onChange={(e) =>
                                      handleSubCardChange(
                                        cardIndex,
                                        subCardIndex,
                                        "question",
                                        e.target.value
                                      )
                                    }
                                    onBlur={(e) => {
                                      e?.preventDefault();
                                      onAutoSaveStory();
                                    }}
                                  />
                                  <div>
                                    <Form.Label>
                                      {errors?.cards?.length > 0 &&
                                        errors?.cards[cardIndex]?.sub_card[
                                          subCardIndex
                                        ]?.question && (
                                          <span className="text-danger">
                                            Question is required
                                          </span>
                                        )}
                                    </Form.Label>
                                  </div>
                                  <Form.Label>Attribution</Form.Label>
                                  <Form.Control
                                    as="textarea"
                                    name="question_attributes"
                                    placeholder="Question Attribution"
                                    maxLength={200}
                                    {...register(
                                      `cards[${cardIndex}].sub_card[${subCardIndex}].question_attributes`,
                                      { required: true }
                                    )}
                                    value={subCard.question_attributes}
                                    onChange={(e) =>
                                      handleSubCardChange(
                                        cardIndex,
                                        subCardIndex,
                                        "question_attributes",
                                        e.target.value
                                      )
                                    }
                                    onBlur={(e) => {
                                      e?.preventDefault();
                                      onAutoSaveStory();
                                    }}
                                  />
                                  <div>
                                    <Form.Label>
                                      {errors?.cards?.length > 0 &&
                                        errors?.cards[cardIndex]?.sub_card[
                                          subCardIndex
                                        ]?.question_attributes && (
                                          <span className="text-danger">
                                            Attribution is required
                                          </span>
                                        )}
                                    </Form.Label>
                                  </div>
                                  <Form.Label>Answer</Form.Label>
                                  <Form.Control
                                    as="textarea"
                                    name="answer"
                                    // placeholder="Enter answer"
                                    maxLength={200}
                                    {...register(
                                      `cards[${cardIndex}].sub_card[${subCardIndex}].answer`,
                                      { required: true }
                                    )}
                                    value={subCard.answer}
                                    onChange={(e) =>
                                      handleSubCardChange(
                                        cardIndex,
                                        subCardIndex,
                                        "answer",
                                        e.target.value
                                      )
                                    }
                                    onBlur={(e) => {
                                      e?.preventDefault();
                                      onAutoSaveStory();
                                    }}
                                  />
                                  <div>
                                    <Form.Label>
                                      {errors?.cards?.length > 0 &&
                                        errors?.cards[cardIndex]?.sub_card[
                                          subCardIndex
                                        ]?.answer && (
                                          <span className="text-danger">
                                            Answer is required
                                          </span>
                                        )}
                                    </Form.Label>
                                  </div>
                                  <Form.Label>Attribution</Form.Label>
                                  <Form.Control
                                    as="textarea"
                                    name="answer_attributes"
                                    placeholder="Answer Attribution"
                                    maxLength={200}
                                    {...register(
                                      `cards[${cardIndex}].sub_card[${subCardIndex}].answer_attributes`,
                                      { required: true }
                                    )}
                                    value={subCard.answer_attributes}
                                    onChange={(e) =>
                                      handleSubCardChange(
                                        cardIndex,
                                        subCardIndex,
                                        "answer_attributes",
                                        e.target.value
                                      )
                                    }
                                    onBlur={(e) => {
                                      e?.preventDefault();
                                      onAutoSaveStory();
                                    }}
                                  />
                                  <div>
                                    <Form.Label>
                                      {errors?.cards?.length > 0 &&
                                        errors?.cards[cardIndex]?.sub_card[
                                          subCardIndex
                                        ]?.answer_attributes && (
                                          <span className="text-danger">
                                            Answer Attribution is required
                                          </span>
                                        )}
                                    </Form.Label>
                                  </div>
                                </div>
                              )}
                              {subCard.type.toLowerCase() === "summary" && (
                                <div className="width-100">
                                  <Form.Label>
                                    {" "}
                                    <svg
                                      data-name="Layer 1"
                                      width="24"
                                      height="24"
                                      fill="currentColor"
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                    >
                                      <path d="M4 5.02h16v1.29H4zM4 11.35h16v1.29H4zM4 17.69h10.93v1.29H4z"></path>
                                    </svg>
                                    Summary
                                  </Form.Label>

                                  <Form.Control
                                    as="textarea"
                                    name="summary"
                                    placeholder="Enter Summary"
                                    // {...register(
                                    //   `cards[${cardIndex}].sub_card[${subCardIndex}].summary`
                                    // )}
                                    value={subCard.summary}
                                    onChange={(e) =>
                                      handleSubCardChange(
                                        cardIndex,
                                        subCardIndex,
                                        "summary",
                                        e.target.value
                                      )
                                    }
                                    onBlur={(e) => {
                                      e?.preventDefault();
                                      onAutoSaveStory();
                                    }}
                                  />
                                  <div className={`text-end`}>{`${countWordsAndChars(subCard?.summary)
                                    ?.wordCount
                                    } words / ${countWordsAndChars(subCard?.summary)
                                      ?.characters
                                    } characters`}</div>
                                </div>
                              )}
                              {subCard.type.toLowerCase() === "image" && (
                                <div className="width-100">
                                  <DropBox
                                    editEnabled={editEnabled}
                                    register={register}
                                    setValue={setValue}
                                    imgUrl={imgUrl}
                                    setImgUrl={setImgUrl}
                                    files={files}
                                    setFiles={setFiles}
                                    setIsImgEditorShown={setIsImgEditorShown}
                                    cardIndex={cardIndex}
                                    subCardIndex={subCardIndex}
                                    handleSubCardChange={handleSubCardChange}
                                    subCard={subCard}
                                    cards={cards}
                                    isComponent={isComponent}
                                    setSelectedFile={setSelectedFile}
                                    setIsComponent={setIsComponent}
                                    setIsImgEditorShownForEditor={
                                      setIsImgEditorShownForEditor
                                    }
                                    setCardIndexObj={setCardIndexObj}
                                    currentImgId={currentImgId}
                                    setCurrentImgId={setCurrentImgId}
                                    isLoading={isLoading}
                                    setIsLoading={setIsLoading}
                                    getValues={getValues}
                                    onAutoSaveStory={onAutoSaveStory}
                                  />
                                </div>
                              )}
                              {subCard.type.toLowerCase() === "also read" && (
                                <div className="width-100">
                                  <Form.Label>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      id="Layer_1"
                                      data-name="Layer 1"
                                      viewBox="0 0 24 24"
                                      width="24"
                                      height="24"
                                      fill="currentColor"
                                    >
                                      <defs></defs>
                                      <path
                                        d="M612.41 358.73h-13a2.37 2.37 0 0 1-2.37-2.37v-13.05a2.37 2.37 0 0 1 2.37-2.36h13a2.37 2.37 0 0 1 2.37 2.36v13.05a2.37 2.37 0 0 1-2.37 2.37zm-13-16.78a1.36 1.36 0 0 0-1.36 1.36v13.05a1.36 1.36 0 0 0 1.36 1.36h13a1.36 1.36 0 0 0 1.36-1.36v-13.05a1.36 1.36 0 0 0-1.36-1.36z"
                                        transform="translate(-597 -338)"
                                      ></path>
                                      <path d="M3.34 6.83h11.1v1.21H3.34zM3.34 10.89h11.1v1.21H3.34zM3.34 14.77h6.64v1.21H3.34z"></path>
                                      <path
                                        d="M615.35 338H602.3a2.37 2.37 0 0 0-2.23 1.6h1.14a1.33 1.33 0 0 1 1.09-.59h13.05a1.36 1.36 0 0 1 1.36 1.36v13a1.35 1.35 0 0 1-.61 1.11v1.13a2.37 2.37 0 0 0 1.61-2.24v-13a2.37 2.37 0 0 0-2.36-2.37"
                                        transform="translate(-597 -338)"
                                      ></path>
                                    </svg>
                                    Link A Story
                                  </Form.Label>

                                  <Select
                                    className={`outline-none`}
                                    name="story"
                                    closeMenuOnSelect={true}
                                    components={animatedComponents}
                                    options={storyList}
                                    // {...register(
                                    //   `cards[${cardIndex}].sub_card[${subCardIndex}].story`
                                    // )}
                                    // value={subCard.summary}
                                    onChange={(e) => {
                                      HandleSelectChange(
                                        "story",
                                        e,
                                        cardIndex,
                                        subCardIndex
                                      );
                                    }}
                                    onBlur={(e) => {
                                      e?.preventDefault();
                                      onAutoSaveStory();
                                    }}
                                    // onChange={(e) => HandleSelectChange("tags", e)}
                                    value={selectedStoryList}
                                  ></Select>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}

                {/* Button to remove card */}
                <div className=" ms-1 card-remove-btn">
                  <div>
                    <button
                      data-tooltip-id="my-tooltip-0"
                      className="delete-btn ms-1 "
                      fdprocessedid="gd3vti"
                      type="button"
                      onClick={(e) => {
                        if (!editEnabled) return
                        e?.preventDefault();
                        handleDelete(cardIndex, "", "card");
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="#5f6978"
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                      >
                        <path d="M4.19 5A.85.85 0 0 1 5 4.1h4l.32-.67a.76.76 0 0 1 .28-.33 1 1 0 0 1 .46-.1h4a.93.93 0 0 1 .46.12.76.76 0 0 1 .31.33l.32.67h3.95a.85.85 0 0 1 .84.85v1a.41.41 0 0 1-.42.42H4.61a.44.44 0 0 1-.3-.12.41.41 0 0 1-.12-.3zm13.89 14.39a1.61 1.61 0 0 1-.53 1.13 1.68 1.68 0 0 1-1.16.46H7.74a1.68 1.68 0 0 1-1.16-.46 1.61 1.61 0 0 1-.53-1.13l-.7-11.46a.47.47 0 0 1 .12-.31.39.39 0 0 1 .3-.14h12.59a.36.36 0 0 1 .3.14.47.47 0 0 1 .12.31z"></path>
                      </svg>
                    </button>
                    <div
                      className={`d-flex up-down-btn justify-content-between mt-2`}
                    >
                      <span
                        role="button"
                        onClick={() => { if (editEnabled) moveCard(cardIndex, cardIndex - 1) }}
                        disabled={cardIndex === 0 || !editEnabled}
                      >
                        <i className="fa  fa-arrow-circle-up"></i>
                      </span>
                      <span
                        role="button"
                        onClick={() => { if (editEnabled) moveCard(cardIndex, cardIndex + 1) }}
                        disabled={cardIndex === cards.length - 1 || !editEnabled}
                      >
                        <i className="fa  fa-arrow-circle-down"></i>
                      </span>
                    </div>
                  </div>
                </div>

                <div>
                  <div className="main-editor-bx mt-4 col-lg-8 row ">
                    {!topToolbar && isShowHeader && toolbarIndex === cardIndex ? (
                      <div
                        className="col-lg-1 px-0 col-sm-12 add-icon"
                        onClick={(e) => {
                          if (!editEnabled) return
                          handleToolbar(cardIndex);
                          setIsShowHeader(false);
                          setTopToolbar(false)
                        }}
                      >
                        <svg
                          className="rotate"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="var(--mono-4)"
                          width="32"
                          height="32"
                          viewBox="0 0 24 24"
                        >
                          <path d="M19.6 16.26a9 9 0 0 1-3.18 3.18 8.64 8.64 0 0 1-8.72 0 8.81 8.81 0 0 1-3.18-3.18 8.42 8.42 0 0 1-1.18-4.36 8.4 8.4 0 0 1 1.18-4.36A8.66 8.66 0 0 1 7.7 4.36a8.4 8.4 0 0 1 4.36-1.18 8.42 8.42 0 0 1 4.36 1.18 8.81 8.81 0 0 1 3.18 3.18 8.64 8.64 0 0 1 0 8.72zm-1-8.18A7.64 7.64 0 0 0 12 4.3a7.39 7.39 0 0 0-3.8 1 7.59 7.59 0 0 0-2.72 2.8 7.45 7.45 0 0 0-1 3.81 7.36 7.36 0 0 0 1 3.8 7.59 7.59 0 0 0 2.78 2.76 7.46 7.46 0 0 0 3.82 1 7.35 7.35 0 0 0 3.79-1 7.51 7.51 0 0 0 2.76-2.78 7.61 7.61 0 0 0 0-7.61zm-2.07 4a.41.41 0 0 1-.13.3.4.4 0 0 1-.29.12h-3.42V16a.41.41 0 0 1-.12.3.38.38 0 0 1-.3.13h-.42a.38.38 0 0 1-.3-.13.42.42 0 0 1-.13-.3v-3.47H8a.41.41 0 0 1-.3-.12.44.44 0 0 1-.12-.3v-.42a.41.41 0 0 1 .12-.3.38.38 0 0 1 .3-.13h3.44V7.82a.42.42 0 0 1 .13-.3.41.41 0 0 1 .3-.12h.42a.41.41 0 0 1 .42.42v3.44h3.45a.37.37 0 0 1 .29.13.38.38 0 0 1 .13.3z"></path>
                        </svg>
                      </div>
                    ) : (
                      <div
                        className="col-lg-1 px-0 col-sm-12 add-icon"
                        onClick={(e) => {
                          if (!editEnabled) return
                          handleToolbar(cardIndex);
                          setIsShowHeader(true);
                          setTopToolbar(false)
                        }}
                      >
                        <svg
                          className="rotate-original"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="var(--mono-4)"
                          width="32"
                          height="32"
                          viewBox="0 0 24 24"
                        >
                          <path d="M19.6 16.26a9 9 0 0 1-3.18 3.18 8.64 8.64 0 0 1-8.72 0 8.81 8.81 0 0 1-3.18-3.18 8.42 8.42 0 0 1-1.18-4.36 8.4 8.4 0 0 1 1.18-4.36A8.66 8.66 0 0 1 7.7 4.36a8.4 8.4 0 0 1 4.36-1.18 8.42 8.42 0 0 1 4.36 1.18 8.81 8.81 0 0 1 3.18 3.18 8.64 8.64 0 0 1 0 8.72zm-1-8.18A7.64 7.64 0 0 0 12 4.3a7.39 7.39 0 0 0-3.8 1 7.59 7.59 0 0 0-2.72 2.8 7.45 7.45 0 0 0-1 3.81 7.36 7.36 0 0 0 1 3.8 7.59 7.59 0 0 0 2.78 2.76 7.46 7.46 0 0 0 3.82 1 7.35 7.35 0 0 0 3.79-1 7.51 7.51 0 0 0 2.76-2.78 7.61 7.61 0 0 0 0-7.61zm-2.07 4a.41.41 0 0 1-.13.3.4.4 0 0 1-.29.12h-3.42V16a.41.41 0 0 1-.12.3.38.38 0 0 1-.3.13h-.42a.38.38 0 0 1-.3-.13.42.42 0 0 1-.13-.3v-3.47H8a.41.41 0 0 1-.3-.12.44.44 0 0 1-.12-.3v-.42a.41.41 0 0 1 .12-.3.38.38 0 0 1 .3-.13h3.44V7.82a.42.42 0 0 1 .13-.3.41.41 0 0 1 .3-.12h.42a.41.41 0 0 1 .42.42v3.44h3.45a.37.37 0 0 1 .29.13.38.38 0 0 1 .13.3z"></path>
                        </svg>
                      </div>
                    )}

                    <div
                      className={
                        !topToolbar && isShowHeader && toolbarIndex === cardIndex
                          ? "col-lg-8 px-0 col-sm-12 editor-bx "
                          : "col-lg-8 px-0 col-sm-12 editor-bx  invisible"
                      }
                    >
                      <button
                        data-tooltip-id="my-tooltip-1"
                        // onClick={(e) => handleAddEntry(e, "Text")}
                        onClick={(e) => addSubCard(e, "Text", cardIndex)}
                      >
                        <svg
                          data-name="Layer 1"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="#5f6978"
                          viewBox="0 0 24 24"
                        >
                          <path d="M7.01 11.99h1.01V5.85H11v-.86H4.04v.86h2.97v6.14zM9.64 9.86h10.31v1H9.64zM4.96 12.86h15v1h-15zM4.96 15.86h15v1h-15zM4.96 18.86h15v1h-15z"></path>
                        </svg>
                      </button>
                      <ReactTooltip
                        id="my-tooltip-1"
                        place="bottom"
                        content="Text"
                      />
                      <button
                        data-tooltip-id="my-tooltip-4"
                        onClick={(e) => addSubCard(e, "Quote", cardIndex)}
                      >
                        <svg
                          width="24"
                          height="24"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fillRule="evenodd"
                            d="M8.56 21v-4.32l.44-.05c.13 0 3.13-.41 2.91-4.66H6.78V3h10.31v.48s.16 3.94.08 8.21S14 20.63 9.1 21zm1-3.48v2.39c4-.59 6.55-4.64 6.61-8.24.06-3.37 0-6.51-.06-7.68H7.8v7h5.05l.05.44c.45 4.57-2.23 5.78-3.33 6.09z"
                          ></path>
                        </svg>
                      </button>
                      <ReactTooltip
                        id="my-tooltip-4"
                        place="bottom"
                        content="Quote"
                      />
                      <button
                        data-tooltip-id="my-tooltip-2"
                        onClick={(e) => addSubCard(e, "Blockquote", cardIndex)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="24"
                          height="24"
                          fill="#5f6978"
                        >
                          <path
                            d="M12.23 3.77H3V12h4.6c.2 3.89-2.49 4.25-2.6 4.26l-.41.05v3.95h.49c4.39-.3 7.18-4.64 7.24-8.48s-.07-7.47-.07-7.5zm-.81 7.93c-.06 3.29-2.33 7-5.92 7.53v-2.18c1-.29 3.38-1.41 3-5.58v-.41H3.91V4.68h7.45c.03 1.06.11 3.94.06 7.02zm9.49-7.49v-.44H13.7v.91H20c0 1.06.11 3.94.05 7-.06 3.29-2.32 7-5.92 7.53v-2.16c1-.29 3.38-1.41 3-5.58v-.41H13.7V12h2.56c.19 3.89-2.49 4.25-2.61 4.26l-.4.05v3.95h.48c4.39-.3 7.18-4.64 7.25-8.48s-.07-7.47-.07-7.5"
                            fillRule="evenodd"
                          ></path>
                        </svg>
                      </button>
                      <ReactTooltip
                        id="my-tooltip-2"
                        place="bottom"
                        content="Blockquote"
                      />
                      <button
                        data-tooltip-id="my-tooltip-3"
                        // onClick={() => handleAddEntry(e, "CTA")}
                        onClick={(e) => addSubCard(e, "CTA", cardIndex)}
                      >
                        <svg
                          viewBox="0 0 24 24"
                          width="24"
                          height="24"
                          fill="#5f6978"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="m14.49 13.04c-0.29 0.09-0.5 0.32-0.56 0.61-0.04 0.17 0.14 4.87 0.21 5.59 0.04 0.31 0.07 0.37 0.29 0.52 0.39 0.27 0.75 0.24 1.37-0.13 0.59-0.36 0.47-0.44 1.19 0.79 0.28 0.48 0.58 0.92 0.68 0.98 0.12 0.08 0.32 0.13 0.53 0.13 0.28 0 0.43-0.06 0.85-0.3 0.64-0.38 0.87-0.59 0.95-0.89 0.09-0.34 0.02-0.54-0.56-1.54-0.27-0.47-0.5-0.88-0.51-0.91s0.21-0.21 0.49-0.41c0.42-0.29 0.52-0.39 0.55-0.58 0.08-0.38-0.01-0.7-0.25-0.94-0.13-0.12-1.17-0.83-2.33-1.59-2.26-1.48-2.34-1.52-2.9-1.33zm2.51 2.21c1.09 0.72 1.98 1.33 1.98 1.35s-0.3 0.25-0.67 0.51c-0.64 0.44-0.67 0.48-0.58 0.61 0.32 0.51 1.32 2.29 1.32 2.34 0 0.06-0.76 0.55-0.85 0.55-0.01 0-0.34-0.57-0.75-1.27-0.4-0.7-0.73-1.28-0.74-1.29-0.01-0.02-0.37 0.18-0.8 0.43-0.44 0.25-0.8 0.44-0.82 0.43-0.02-0.02-0.24-4.54-0.24-4.9 0-0.08 0.03-0.11 0.09-0.09 0.04 0.02 0.97 0.62 2.06 1.33zm-10.97-3.72v0.47h6.28 6.27v-0.47-0.48h-6.27-6.28v0.48zm-2.59-6.44c-0.69 0.16-1.34 0.69-1.66 1.35l-0.19 0.37-0.02 4.59c-0.01 4.49-0.01 4.59 0.12 4.96 0.27 0.7 0.85 1.25 1.56 1.47 0.4 0.13 0.62 0.14 4.59 0.14h4.16v-0.48-0.47h-4.16-4.15l-0.37-0.19c-0.45-0.23-0.72-0.59-0.81-1.09-0.09-0.49-0.09-8 0-8.49 0.09-0.5 0.36-0.86 0.81-1.09l0.35-0.19h8.33 8.33l0.35 0.19c0.45 0.23 0.72 0.59 0.81 1.09 0.04 0.21 0.07 1.93 0.07 4.25v3.89h0.44 0.44l-0.01-4.29-0.02-4.29-0.19-0.38c-0.25-0.51-0.67-0.91-1.2-1.17l-0.44-0.21s-8.44-0.01-8.44-0.01c-4.78 0-8.55 0.02-8.7 0.05z"
                            stroke="none"
                          ></path>
                        </svg>
                      </button>
                      <ReactTooltip
                        id="my-tooltip-3"
                        place="bottom"
                        content="CTA"
                      />
                      <button
                        data-tooltip-id="my-tooltip-5"
                        // onClick={() => handleAddEntry(e, "Big Fact")}
                        onClick={(e) => addSubCard(e, "Big Fact", cardIndex)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="24"
                          height="24"
                          fill="currentColor"
                        >
                          <path
                            d="M18.56 3.12H5.23a2.4 2.4 0 0 0-2.42 2.4v13.2a2.41 2.41 0 0 0 2.42 2.4h13.33a2.41 2.41 0 0 0 2.44-2.4V5.52a2.4 2.4 0 0 0-2.44-2.4zM20 18.72a1.38 1.38 0 0 1-1.39 1.38H5.23a1.38 1.38 0 0 1-1.39-1.38V5.52a1.38 1.38 0 0 1 1.39-1.38h13.33A1.38 1.38 0 0 1 20 5.52z"
                            fillRule="evenodd"
                          ></path>
                          <path
                            d="M12.56 16.57a.55.55 0 0 1-.29.08c-.08 0-.29 0-.29-.39v-.18l.79-5.49-.19-.09-2.09.3-.06.35.77.35-.38 2.67c-.17 1.19-.28 2-.35 2.56a.53.53 0 0 0 .22.46 1.14 1.14 0 0 0 .69.17 1.64 1.64 0 0 0 .71-.15 2.36 2.36 0 0 0 .55-.34c.13-.12.23-.22.28-.22l-.12-.27-.27.19m.29-8.94a.94.94 0 0 0-.9 0 1 1 0 0 0-.35.34 1 1 0 0 0-.12.48.65.65 0 0 0 .1.36.86.86 0 0 0 .28.29.79.79 0 0 0 .37.1 1.12 1.12 0 0 0 .53-.13.84.84 0 0 0 .34-.36 1.06 1.06 0 0 0 .12-.5.64.64 0 0 0-.1-.35.62.62 0 0 0-.27-.26"
                            fillRule="evenodd"
                          ></path>
                        </svg>
                      </button>
                      <ReactTooltip
                        id="my-tooltip-5"
                        place="bottom"
                        content="Big Fact"
                      />
                      <button
                        data-tooltip-id="my-tooltip-6"
                        onClick={(e) => addSubCard(e, "Image", cardIndex)}
                      >
                        <svg width="24" height="24" fill="currentColor">
                          <path
                            d="M18.64 3.13H5.42A2.39 2.39 0 0 0 3 5.52v13.21a2.4 2.4 0 0 0 2.39 2.4h13.25a2.4 2.4 0 0 0 2.36-2.4V5.52a2.39 2.39 0 0 0-2.39-2.39M4.75 19.94l3.41-4.39 2.16 1.76.64-.78L8 14.12l-3.9 5a1.76 1.76 0 0 1-.06-.4V5.52a1.38 1.38 0 0 1 1.38-1.38h13.22A1.38 1.38 0 0 1 20 5.52v9.13l-3.41-3.8L9 20.12H5.42a1.4 1.4 0 0 1-.67-.18m13.89.18h-8.36l6.37-7.72L20 16.17v2.56a1.38 1.38 0 0 1-1.38 1.39"
                            fill="none"
                          ></path>
                          <path
                            d="M18.64 3.13H5.42A2.39 2.39 0 0 0 3 5.52v13.21a2.4 2.4 0 0 0 2.39 2.4h13.25a2.4 2.4 0 0 0 2.36-2.4V5.52a2.39 2.39 0 0 0-2.39-2.39M4.75 19.94l3.41-4.39 2.16 1.76.64-.78L8 14.12l-3.9 5a1.76 1.76 0 0 1-.06-.4V5.52a1.38 1.38 0 0 1 1.38-1.38h13.22A1.38 1.38 0 0 1 20 5.52v9.13l-3.41-3.8L9 20.12H5.42a1.4 1.4 0 0 1-.67-.18m13.89.18h-8.36l6.37-7.72L20 16.17v2.56a1.38 1.38 0 0 1-1.38 1.39"
                            fillRule="evenodd"
                          ></path>
                        </svg>
                      </button>
                      <ReactTooltip
                        id="my-tooltip-6"
                        place="bottom"
                        content="Image"
                      />
                    </div>
                  </div>
                </div>
              </div>
            );
          })}

        {/* Button to add card */}
        <div className="col-sm-12  mb-15 d-flex justify-content-center">
          <button className={`btn btn-primary`} type="button" onClick={() => { const values = getValues(); addCard(values?.story_type === "Live Blog") }} disabled={!editEnabled}>
            Add Card
          </button>
        </div>
      </form>
    </div>
  );
};
export default TextEditorComponent;
