import React, { useEffect } from "react";
import { ConvertArrayTOString } from "../../common/utils/arrayToString";

function SectionCreate(props) {
  const {
    onSubmit,
    onUpdate,
    register,
    handleSubmit,
    errors,
    sectionList,
    sectionItem,
    setValue,
    show,
    setShow,
    generateSlug,
  } = props;

  const pageTitle = sectionItem?._id ? "Update Section" : "Create Section";
  useEffect(() => {
    setValue("section_name", sectionItem?.section_name);
    setValue("slug", sectionItem?.slug);
    setValue("display_name", sectionItem?.display_name);
    // setValue("parent_section", sectionItem?.parent_section);
    setValue("page_title", sectionItem?.page_title);
    setValue("meta_title", sectionItem?.meta_title);
    setValue("description", sectionItem?.description);
    setValue("keywords", sectionItem?.keywords);
    setValue("status", sectionItem?.status || "Active");
    setValue("parent_section", sectionItem?.parent_section?.[0]);
  }, [sectionItem]);
  return (
    <>
      <div className=" col-md-12 m-auto">
        <div className="card-header">
          <h3 className="text-bold">{pageTitle}</h3>
        </div>
        <p></p>
        <div className="">
          <form
            onSubmit={
              sectionItem?._id ? handleSubmit(onUpdate) : handleSubmit(onSubmit)
            }
          >
            <div className="row mb-15">
              <label
                htmlFor="type"
                className="col-sm-4 col-form-label text-right"
              >
                Section Name
              </label>
              <div className="col-lg-8">
                <input
                  type="text"
                  name="section_name"
                  {...register("section_name", { required: true })}
                  onChange={(e) => {
                    e?.preventDefault()
                    generateSlug(e);
                  }}
                  className="form-control"
                />
                {errors.section_name && (
                  <span className="text-danger">
                    {" "}
                    Section Name is required.
                  </span>
                )}
              </div>
            </div>
            <div className="row mb-15">
              <label
                htmlFor="display_name"
                className="col-sm-4 col-form-label text-right"
              >
                Display Name
              </label>
              <div className="col-lg-8">
                <input
                  type="text"
                  name="display_name"
                  className="form-control"
                  {...register("display_name", { required: true })}
                />
                {errors.display_name && (
                  <span className="text-danger">Display Name is required.</span>
                )}
              </div>
            </div>

            <div className="row mb-15">
              <label
                htmlFor="parent_section"
                className="col-sm-4 col-form-label text-right"
              >
                Parent Section
              </label>
              <div className="col-lg-8">
                <select
                  name="parent_section"
                  {...register("parent_section")}
                  className="form-control"
                >
                  <option value={""}>select</option>
                  {sectionList?.length > 0 &&
                    sectionList?.map((item, key) => {
                      return (
                        item?.status === "Active" && (
                          <option value={item?.section_name} key={key}>
                            {item?.parent_section?.length > 0
                              ? `${item?.section_name} > ${ConvertArrayTOString(
                                item?.parent_section,
                                "section"
                              )}`
                              : `${item?.section_name}`}
                          </option>
                        )
                      );
                    })}
                </select>
              </div>
            </div>
            <div className="row mb-15">
              <label
                htmlFor="parent_section"
                className="col-sm-4 col-form-label text-right"
              >
                Slug
              </label>
              <div className="col-lg-8">
                <input
                  className="form-control"
                  type="text"
                  name="slug"
                  {...register("slug")}
                />
              </div>
            </div>

            <div className="conatiner-flex">
              <div className="row">
                <div className="col-lg-2 text-right p-lg-2">
                  <strong>SEO</strong>
                </div>
                <div className="col-lg-8">
                  <hr className="horizonal_line_section_create" />
                </div>
              </div>
            </div>
            <div className="row mb-15">
              <label
                htmlFor="page_title"
                className="col-sm-4 col-form-label text-right"
              >
                Page Title
              </label>
              <div className="col-lg-8">
                <input
                  type="text"
                  name="page_title"
                  className="form-control"
                  {...register("page_title", { required: true })}
                />
                {errors.page_title && (
                  <span className="text-danger"> Page Title is required.</span>
                )}
              </div>
            </div>

            <div className="row mb-15">
              <label
                htmlFor="meta_title"
                className="col-sm-4 col-form-label text-right"
              >
                Meta Title
              </label>
              <div className="col-lg-8">
                <input
                  type="text"
                  name="meta_title"
                  className="form-control"
                  {...register("meta_title", { required: true })}
                />
                {errors.meta_title && (
                  <span className="text-danger">Meta Title is required.</span>
                )}
              </div>
            </div>

            <div className="row mb-15">
              <label
                htmlFor="description"
                className="col-sm-4 col-form-label text-right"
              >
                Description
              </label>
              <div className="col-lg-8">
                <input
                  type="text"
                  name="description"
                  className="form-control"
                  {...register("description", { required: true })}
                />
                {errors.description && (
                  <span className="text-danger">Description is required.</span>
                )}
              </div>
            </div>

            <div className="row mb-15">
              <label
                htmlFor="keywords"
                className="col-sm-4 col-form-label text-right"
              >
                Keywords
              </label>
              <div className="col-lg-8">
                <input
                  type="text"
                  name="keywords"
                  className="form-control"
                  {...register("keywords", { required: true })}
                />
                {errors.keywords && (
                  <span className="text-danger">Keywords is required.</span>
                )}
              </div>
            </div>

            <div className="row mb-15">
              <label
                htmlFor="status"
                className="col-sm-4 col-form-label text-right"
              >
                Status
              </label>
              <div className="col-lg-8">
                <select
                  name="status"
                  {...register("status")}
                  defaultValue={"Active"}
                  className="form-control"
                >
                  <option value={"Active"}>Active</option>
                  <option value={"Inactive"}>Inactive</option>
                </select>
              </div>
            </div>

            <div className="row mb-15">
              <div className="col-sm-12 text-right">
                <button
                  type="submit"
                  className="btn btn-primary btn-round mr-5"
                >
                  {sectionItem?._id ? `Update` : `Submit`}
                </button>
                <div
                  // to="/section/manage"
                  className="btn btn-primary btn-round"
                  data-bs-dismiss="offcanvas"
                  // type="reset"
                  onClick={(e) => {
                    e?.preventDefault();
                    setShow(false);
                  }}
                >
                  Cancel
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default SectionCreate;
