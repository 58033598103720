export const alphabetsOptions = [
  { value: "A", label: "A" },
  { value: "B", label: "B" },
  { value: "C", label: "C" },
  { value: "D", label: "D" },
  { value: "E", label: "E" },
  { value: "F", label: "F" },
  { value: "G", label: "G" },
  { value: "H", label: "H" },
  { value: "I", label: "I" },
  { value: "J", label: "J" },
  { value: "K", label: "K" },
  { value: "L", label: "L" },
  { value: "M", label: "M" },
  { value: "N", label: "N" },
  { value: "O", label: "O" },
  { value: "P", label: "P" },
  { value: "Q", label: "Q" },
  { value: "R", label: "R" },
  { value: "S", label: "S" },
  { value: "T", label: "T" },
  { value: "U", label: "U" },
  { value: "V", label: "V" },
  { value: "W", label: "W" },
  { value: "X", label: "X" },
  { value: "Y", label: "Y" },
  { value: "Z", label: "Z" },
];
export const templateOption = [
  { label: "Text", value: "text" },
  { label: "Listicle", value: "listicle" },
  { label: "Video", value: "video" },
  { label: "News elsewhere", value: "newsElsewhere" },
  { label: "Publication", value: "publication" },
  { label: "Review", value: "review" },
  { label: "Syndicated", value: "syndicated" },
  { label: "Photo", value: "photo" },
  { label: "Interview", value: "interview" },
  { label: "Live Blog", value: "liveBlog" },
  { label: "Visual Story", value: "visualStory" },
  { label: "Story listicle", value: "storyListicle" },
];

export const postionOptionArr = [
  { value: "Header", label: "Header" },
  { value: "Footer", label: "Footer" },
  { value: "Bottom", label: "Bottom" }
];

export const typeArr = [
  { value: "Pages", label: "Pages" },
  { value: "Collection", label: "Collection" },
  { value: "Section", label: "Section" },
  { value: "Collections Of Collection", label: "Collections Of Collection" },
  { value: "Tags", label: "Tags" },
  { value: "External", label: "External" },
  { value: "Placeholder", label: "Placeholder" },
]

export const selectOption = [{ label: "", value: "" }]