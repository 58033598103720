import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { postionOptionArr, typeArr } from "../../common/common-data/alphabets";
import axios from "axios";
import config from "../../../config";
import { apiEndpoints } from "../../common/api/apiEndpoints";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { GetListApi } from "../../common/api/api";

const usePageHook = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [submittedPage, setSubmittedPage] = useState(null);
  const [pageList, setPageList] = useState([]);
  const [formData, setFormData] = useState({
    show_header: false,
    show_footer: false,
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
  } = useForm();

  const [pagesList, setPagesList] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchAndSetPages = async () => {
    try {
      const response = await axios.get(`${config.apiUrl}/pages`);
      setPagesList(response.data);
    } catch (error) {
      console.error("Error fetching pages:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAndSetPages();
  }, []);

  // add form
  const onSubmit = async (data) => {
    data.showHeader = formData.show_header;
    data.showFooter = formData.show_footer;
    try {
      const response = await axios.post(`${config.apiUrl}/pages`, data);
      setSubmittedPage(response.data);
      toast.success("Page created successfully");

      setPageList((prevPageList) => [...prevPageList, response.data]);
      navigate("/pages");
    } catch (error) {
      console.log("Error creating page:", error);
      toast.error("Error creating page");
    }
  };
  // delete page
  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`${config.apiUrl}${apiEndpoints.pageById(id)}`)
          .then((response) => {
            Swal.fire({
              title: "Delete Successfully",
              icon: "success",
            });
            fetchAndSetPages();
          })
          .catch((error) => {
            console.error("Error deleting item:", error);
            toast.error("An error occurred while deleting");
          });
      }
    });
  };

  // update page form
  const onPageUpdate = async (data) => {
    try {
      const userData = sessionStorage.getItem("user");
      const user = userData ? JSON.parse(userData) : null;
      let dt = new Date();
      data.updated_by = user?.name;
      data.updated_on = dt;

      const response = await axios.put(
        `${config.apiUrl}${apiEndpoints.pageById(id)}`,
        data
      );

      if (response.data) {
        Swal.fire({
          title: "Update Successfully",
          icon: "success",
        });
        navigate("/pages");
      } else {
        toast.error(response.data.error);
      }
    } catch (error) {
      console.error("Error updating page:", error);
      toast.error("An error occurred while updating");
    }
  };

  const fetchPageById = async (id) => {
    try {
      const response = await axios.get(
        `${config.apiUrl}${apiEndpoints?.pageById(id)}`
      );
      if (response?.data) {
        setValue("title", response.data.title);
        setValue("content", response.data.content);
        setValue("created_by", response.data.created_by);
        setValue("meta_description", response.data.meta_description);
        setValue("meta_title", response.data.meta_title);
        setValue("mime_type", response.data.mime_type);
        setFormData("mime_type", response.data.mime_type);
        setValue("meta_keywords", response.data.meta_keywords);
        setValue("slug", response.data.show_footer);
        setFormData("show_footer", response.data.show_footer);
        setFormData("show_header", response.data.show_header);
        setValue("status", response.data.status);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (id) {
      fetchPageById(id);
    }
  }, [id]);

  const FetchPageList = async (name, status) => {
    let params = {
      status: status,
      name: name,
    };
    const page = await GetListApi(`page`, params);
    if (page?.length > 0) {
      setPageList(page);
    } else {
      setPageList([]);
    }
  };

  return {
    register,
    handleSubmit,
    setValue,
    errors,
    handleDelete,
    pagesList,
    loading,
    onPageUpdate,
    id,
    onSubmit,
    handleChange,
    submittedPage,
    setSubmittedPage,
    formData,
    setFormData,
    getValues,
    FetchPageList,
  };
};

export default usePageHook;
