import React, { useContext, useEffect, useState } from "react";
import usePageshook from "./hook";
import { Link } from "react-router-dom";
import Pagination from "../../common/pagination";
import moment from "moment";
import { UpdateDataApi } from "../../common/api/api";
import { apiEndpoints } from "../../common/api/apiEndpoints";
import Loader from "../../common/utils/loader";
import _ from "lodash";
import { CheckAccess } from "../../common/utils/roleAccess";
import Swal from "sweetalert2";
import AppContext from "../../appContext";
import { ConvertArrayTOString } from "../../common/utils/arrayToString";

const ListWebStory = () => {
  const {
    totalPages,
    storiesList,
    handleDelete,
    currentPage,
    handlePageChange,
    navigate,
    fetchOurStory,
    loading,
  } = usePageshook();
  const { setAppData } = useContext(AppContext);
  const [isTab, setIsTab] = useState("All");
  const userData = localStorage.getItem("user");
  const user = userData ? JSON.parse(userData) : null;
  useEffect(() => {
    setAppData("");
  }, []);
  return (
    <div>
      <div className="content">
        <div className="content">
          <div className="card col-md-12 m-auto p-30">
            <div className="card-header">
              <h3 className="text-bold">Story</h3>
              <input
                type="search"
                placeholder="Enter title or author"
                className="form-control story-search-input"
                onChange={(e) => {
                  fetchOurStory("", e?.target?.value);
                }}
              />

              {CheckAccess("story", "create") && (
                <div className="timeline-footer text-right">
                  <Link
                    to="/web-story/create"
                    className="btn btn-primary btn-round btn-sm"
                  >
                    Add Web Story
                  </Link>
                </div>
              )}
            </div>
            <div className="card-body">
              <div className={`row mb-15 d-flex justify-content-around`}>
                <div
                  className={
                    isTab === "All"
                      ? "px-0 col-md-1 text-center active-stepper-form-head text-bold mb-0"
                      : "px-0 col-md-1 text-center text-bold mb-0"
                  }
                  role="button"
                  onClick={(e) => {
                    setIsTab("All");
                    fetchOurStory("");
                  }}
                >
                  All
                </div>
                <div
                  className={
                    isTab === "Open"
                      ? "px-0 col-md-1 text-center active-stepper-form-head text-bold mb-0"
                      : "px-0 col-md-1 text-center text-bold mb-0"
                  }
                  role="button"
                  onClick={(e) => {
                    setIsTab("Open");
                    fetchOurStory("open");
                  }}
                >
                  Open
                </div>
                <div
                  className={
                    isTab === "Submitted"
                      ? "px-0 col-md-1 text-center active-stepper-form-head text-bold mb-0"
                      : "px-0 col-md-1 text-center text-bold mb-0"
                  }
                  role="button"
                  onClick={(e) => {
                    setIsTab("Submitted");
                    fetchOurStory("submitted");
                  }}
                >
                  Submitted
                </div>
                <div
                  className={
                    isTab === "Approved"
                      ? "px-0 col-md-1 text-center active-stepper-form-head text-bold mb-0"
                      : "px-0 col-md-1 text-center text-bold mb-0"
                  }
                  role="button"
                  onClick={(e) => {
                    setIsTab("Approved");
                    fetchOurStory("approved");
                  }}
                >
                  Approved
                </div>
                <div
                  className={
                    isTab === "Published"
                      ? "px-0 col-md-1 text-center active-stepper-form-head text-bold mb-0"
                      : "px-0 col-md-1 text-center text-bold mb-0"
                  }
                  role="button"
                  onClick={(e) => {
                    setIsTab("Published");
                    fetchOurStory("published");
                  }}
                >
                  Published
                </div>
                <div
                  className={
                    isTab === "Rejected"
                      ? "px-0 col-sm-1 text-center active-stepper-form-head text-bold mb-0"
                      : "px-0 col-sm-1 text-center text-bold mb-0"
                  }
                  role="button"
                  onClick={(e) => {
                    setIsTab("Rejected");
                    fetchOurStory("rejected");
                  }}
                >
                  Rejected
                </div>
                <div
                  className={
                    isTab === "Retract"
                      ? "px-0 col-sm-1 text-center active-stepper-form-head text-bold mb-0"
                      : "px-0 col-sm-1 text-center text-bold mb-0"
                  }
                  role="button"
                  onClick={(e) => {
                    setIsTab("Retract");
                    fetchOurStory("Retract");
                  }}
                >
                  Retract
                </div>
                <div
                  className={
                    isTab === "Scheduled"
                      ? "px-0 col-sm-1 text-center active-stepper-form-head text-bold mb-0"
                      : "px-0 col-sm-1 text-center text-bold mb-0"
                  }
                  role="button"
                  onClick={(e) => {
                    setIsTab("Scheduled");
                    fetchOurStory("scheduled");
                  }}
                >
                  Scheduled
                </div>
              </div>
              <div className="table-responsive">
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Title</th>
                      <th>Author</th>
                      <th>Story Type</th>
                      <th>Story Status</th>
                      <th>Created On</th>
                      <th>Breaking News</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!storiesList?.length > 0 || loading ? (
                      <Loader />
                    ) : (
                      storiesList?.map((story, index) => {
                        return (
                          <tr key={story?._id}>
                            <td>{index + 1}</td>
                            <td><a href={`/#/web-story/update/${story?._id}`}> {story?.title} </a></td>
                            {/* <td>{story.sub_title}</td> */}
                            <td>
                              {story?.authors?.length > 0
                                ? ConvertArrayTOString(
                                  story?.authors,
                                  "collection"
                                )
                                : "-"}
                            </td>

                            <td>{story?.story_type}</td>
                            <td>{story?.status}</td>

                            {/* <td>{story.created_by || `-`}</td> */}
                            <td>
                              {moment(story?.timestamp).format(
                                "MMMM DD, YYYY | hh:mm:ss a"
                              )}
                            </td>
                            <td>
                              <div className="icheckbox_flat-green">
                                {CheckAccess("story", "update") ? (
                                  <input
                                    type="checkbox"
                                    id="is_breaking_news"
                                    name="is_breaking_news"
                                    className="chk-col-grey"
                                    onChange={async (e) => {
                                      if (CheckAccess("story", "update")) {
                                        await UpdateDataApi(
                                          `${apiEndpoints.storiesById(
                                            story?._id
                                          )}`,
                                          { is_breaking_news: e.target.checked }
                                        );
                                        fetchOurStory();
                                      } else {
                                        Swal.fire({
                                          title: "You don't have permission.",
                                          text: "Sorry, but you do not have the necessary permissions to access this resource. Please contact the administrator for assistance.",
                                          icon: "warning",
                                        });
                                      }
                                    }}
                                    checked={story?.is_breaking_news}
                                  />
                                ) : (
                                  <input
                                    type="checkbox"
                                    id="is_breaking_news"
                                    name="is_breaking_news"
                                    className="chk-col-grey"
                                    onChange={(e) => {
                                      e?.preventDefault();
                                      Swal.fire({
                                        title: "You don't have permission.",
                                        text: "Sorry, but you do not have the necessary permissions to access this resource. Please contact the administrator for assistance.",
                                        icon: "warning",
                                      });
                                    }}
                                    checked={story?.is_breaking_news}
                                  />
                                )}
                              </div>
                            </td>
                            <td className="d-flex align-items-center">
                              {/* {CheckAccess("story", "view") && (
                                <div
                                  onClick={() =>
                                    navigate(`/story/view/${story?._id}`)
                                  }
                                  className="mr-2"
                                  role="button"
                                >
                                  <i className="fa fa-eye"></i>
                                </div>
                              )}
                               {story?.status === "approved" ? (
                                <>
                                  {CheckAccess("story", "update") &&
                                    CheckAccess(
                                      "story",
                                      "update approved story"
                                    ) && (
                                      <div
                                        onClick={() => {
                                          if (
                                            _.lowerCase(story?.story_type) === _.lowerCase("Video Story")
                                          ) {
                                            navigate(
                                              `/video-story/update/${story?._id}`
                                            );
                                            return
                                          }

                                          if (
                                            _.lowerCase(story?.story_type) ===_.lowerCase("Text Story")
                                          ) {
                                            navigate(
                                              `/story/update/${story?._id}`
                                            );
                                            return
                                          }

                                          if (
                                            _.lowerCase(story?.story_type) === _.lowerCase("Web Story")
                                          ) {
                                            navigate(
                                              `/web-story/update/${story?._id}`
                                            );
                                            return
                                          }

                                          setAppData({ story_Id: story?._id });
                                        }}
                                        className="mr-2"
                                        role="button"
                                      >
                                        <i className="fa fa-edit"></i>
                                      </div>
                                    )}
                                </>
                              ) : (
                                CheckAccess("story", "update") && (
                                  <div
                                    onClick={() => {
                                      if (
                                        _.lowerCase(story?.story_type) === _.lowerCase("Video Story")
                                      ) {
                                        navigate(
                                          `/video-story/update/${story?._id}`
                                        );
                                        return
                                      }

                                      if (
                                        _.lowerCase(story?.story_type) ===_.lowerCase("Text Story")
                                      ) {
                                        navigate(
                                          `/story/update/${story?._id}`
                                        );
                                        return
                                      }

                                      if (
                                        _.lowerCase(story?.story_type) === _.lowerCase("Web Story")
                                      ) {
                                        navigate(f
                                          `/web-story/update/${story?._id}`
                                        );
                                        return
                                      }

                                      setAppData({ story_Id: story?._id });
                                    }}
                                    className="mr-2"
                                    role="button"
                                  >
                                    <i className="fa fa-edit"></i>
                                  </div>
                                )
                              )} */}
                              {CheckAccess("story", "delete") && (
                                <div
                                  onClick={(e) => {
                                    e?.preventDefault();
                                    handleDelete(story?._id);
                                  }}
                                  role="button"
                                >
                                  <i className="fa fa-trash"></i>
                                </div>
                              )}
                            </td>
                          </tr>
                        );
                      })
                    )}
                  </tbody>
                </table>
                {!loading && totalPages && storiesList?.length > 0 ? (
                  <div className={`container`}>
                    <div className={`row`}>
                      <div
                        className={`col-sm-12 d-flex justify-content-center px-0`}
                      >
                        <Pagination
                          currentPage={currentPage}
                          totalPages={totalPages}
                          onPageChange={handlePageChange}
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListWebStory;
