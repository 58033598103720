import React from "react";
import { Link } from "react-router-dom";
import useOpinionPollHook from "./hook";
import config from "../../../config";
import { useDropzone } from "react-dropzone";
import { useState } from "react";
import axios from "axios";

const awsBaseUrl = config.awsBaseUrl

const CreateOpinionPoll = () => {
  const {
    register,
    handleSubmit,
    errors,
    optionArr,
    setOptionArr,
    handleAddOptionFields,
    handleRemoveOptionFields,
    onSubmit,
    onUpdate,
    HandleUploadImage,
    handleOptionChange,
    id,
    imgUrl,
    setImgUrl,
    setValue,
    getValues
  } = useOpinionPollHook();

  const uploadImageToS3 = async (files) => {
    axios.post(
      `${config?.apiUrl}/upload-files/image/s3`,
      { files: files[0] },
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    )
      .then(res => {
        const payload = { data: [res?.data?.uploadedFiles?.[0]] }

        const url = awsBaseUrl + '/' + payload?.data?.[0]?.file_path
        const name = payload?.data?.[0]?.file_name

        setValue('heroImage', {
          file_name: name,
          file_path: url
        })

        setImgUrl(url)
      })
      .catch(error => {
        console.error("Error: " + error.message)
      })
  };


  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/*": [],
    },
    onDrop: (acceptedFiles) => {
      setImgUrl("");

      uploadImageToS3(acceptedFiles);
    },
  });

  return (
    <>
      <div className="content">
        <div className="card col-md-12 m-auto p-30">
          <div className="card-header">
            <h3 className="text-bold">
              {id ? `Update` : `Create`} Opinion Polls
            </h3>
          </div>
          <div className="card-body">
            <form
              onSubmit={id ? handleSubmit(onUpdate) : handleSubmit(onSubmit)}
            >
              <div className="row mb-15">
                <label
                  htmlFor="voting"
                  className="col-sm-4 col-form-label text-right"
                >
                  Topic
                </label>
                <div className="col-sm-3">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Topic"
                    name="topic"
                    {...register("topic", { required: true })}
                  />
                  {errors.topic && (
                    <span className="text-danger">Topic is required.</span>
                  )}
                </div>
              </div>

              <div className="row mb-15">
                <label
                  htmlFor="description"
                  className="col-sm-4 col-form-label text-right"
                >
                  Description
                </label>
                <div className="col-sm-3">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Description"
                    name="description"
                    {...register("description", { required: true })}
                  />
                  {errors.description && (
                    <span className="text-danger">
                      Description is required.
                    </span>
                  )}
                </div>
              </div>

              <div className="row mb-15">
                <label
                  htmlFor="heroImage"
                  className="col-sm-4 col-form-label text-right"
                >
                  Hero Image
                </label>
                <div className="col-sm-3">
                  <div
                    {...getRootProps({
                      className: "dropzone border p-3 text-center",
                    })}
                  >
                    <input {...getInputProps()} />
                    <i className="fa fa-cloud-upload"></i>
                    <p>Upload Image</p>
                  </div>

                  {
                    imgUrl &&
                    <div>
                      <img src={imgUrl} alt='image' style={{ margin: '5px 0px' }} />
                      <a href={imgUrl} target="_blank" style={{ cursor: 'pointer' }}>{imgUrl?.slice(0, 30) + '...'}</a>
                    </div>
                  }
                </div>
              </div>

              {optionArr?.length > 0 &&
                optionArr?.map((option, key) => {
                  return (
                    <div className="row mb-15" key={key}>
                      <label
                        htmlFor="option"
                        className="col-sm-4 col-form-label text-right"
                      >
                        {`option ${key + 1}`}
                      </label>
                      <div className="col-sm-3">
                        <input
                          type="text"
                          className="form-control"
                          placeholder={`option ${key + 1}`}
                          name={`option ${key + 1}`}
                          value={option?.value}
                          {...register(`option ${key + 1}`, { required: true })}
                          onChange={(e) => handleOptionChange(e, key)}
                        />
                      </div>
                      <div
                        className="col-md-4 ps-0"
                        onClick={(e) => {
                          handleRemoveOptionFields(key);
                        }}
                      >
                        <a href="#">
                          <i className="fa fa-trash-o"></i>
                        </a>
                      </div>
                    </div>
                  );
                })}

              {optionArr?.length <= 3 && (
                <div className={`row justify-content-center`}>
                  <div
                    className="col-md-4"
                    onClick={(e) => {
                      handleAddOptionFields();
                    }}
                  >
                    <a href="#">
                      <i className="fa fa-plus"></i> Add Option
                    </a>
                  </div>
                </div>
              )}
              <div className="row mb-15">
                <div className="col-md-3">
                  <h4 className="text-right text-bold">Settings</h4>
                </div>
              </div>

              <div className="row mb-15">
                <label
                  htmlFor="viewResults"
                  className="col-sm-4 col-form-label text-right"
                >
                  View Results
                </label>
                <div className="col-sm-3">
                  <select
                    name="viewResult"
                    {...register("viewResult", { required: true })}
                    className="form-control"
                  >
                    <option value={true}>Yes</option>
                    <option value={false}>No</option>
                  </select>
                  {errors.view_result && (
                    <span className="text-danger">
                      view result is required.
                    </span>
                  )}
                </div>
              </div>

              <div className="row mb-15">
                <label
                  htmlFor="allowChangeVote"
                  className="col-sm-4 col-form-label text-right"
                ></label>
                <div className="col-sm-3">
                  <label htmlFor="changeVote">
                    <input
                      type="checkbox"
                      name="allowChangeVote"
                      id="switch-0-0"
                      className="filled-in chk-col-yellow"
                      {...register("allowChangeVote",)}
                    />
                    <span className="ms-2">Allow to Change Vote</span>
                  </label>
                  <div>
                    {/* {errors.allow_change_vote && (
                      <span className="text-danger">Voting is required.</span>
                    )} */}
                  </div>
                </div>
              </div>

              <div className="row mb-15">
                <div className="col-sm-12 text-center">
                  <button
                    className="btn btn-primary btn-round mr-5"
                    type="submit"
                  >
                    {id ? `Update` : `Submit`}
                  </button>
                  <Link
                    to="/opinion-polls"
                    className="btn btn-primary btn-round"
                    type="reset"
                  >
                    Cancel
                  </Link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateOpinionPoll;
