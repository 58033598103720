import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";

import "react-quill/dist/quill.snow.css";

const LiveBlogIntroQuill = (props) => {
    const {
        setValue,
        getValues,
        editEnabled
    } = props;

    const modules = {
        toolbar: [
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ["bold", "italic", "underline", "strike"],
            // [{ font: [] }],
            // [{ color: [] }, { background: [] }],
            [{ align: [] }],
            [{ list: "ordered" }, { list: "bullet" }],
            ["link"],
            // [{ header: '1' }, { header: '2' }, { font: [] }],
            // [{ size: [] }],
            // ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            // [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
            // ['link', 'image', 'video'],
            // ['clean'],
        ],
        clipboard: {
            matchVisual: false,
        },
    };

    const formats = [
        "header",
        "bold",
        "italic",
        "underline",
        "strike",
        "font",
        "color",
        "background",
        "align",
        "list",
        "bullet",
        "link",
        "image",
    ];

    return (
        <>
            <ReactQuill
                className="quill-editor h3"
                theme="snow"
                value={getValues()?.intro}
                onChange={(e) => {
                    setValue('intro', e)
                }}
                onBlur={(e) => {
                    //   if (editEnabled)
                    //     onAutoSaveStory();
                }}
                modules={modules}
                formats={formats}
                placeholder="Enter Intro"
                readOnly={!editEnabled}
            />
        </>
    );
};

export default LiveBlogIntroQuill;
