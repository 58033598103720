// config.js
const config = {
  apiUrl: "https://gambhir-api.ptindia.org",
  // apiUrl: "http://localhost:3011",


  fileUrl: 'https://images.gambhirmudda.com',

  frontendUrl: 'https://gambhirmudda.com',

  s3Key: 'AKIATG6MGWL2LAKSUENP',
  s3SecretAccessKey: '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',
  awsRegion: 'ap-south-1',
  awsBucket: 'images.gambhirmudda.com',

  awsBaseUrl: 'https://images.gambhirmudda.com'
};

export default config;