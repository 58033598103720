import React, { useState } from "react";
import { Badge } from "react-bootstrap";
import useStoreHook from "../hooks/store.hook";
import Loader from "../../../common/utils/loader";
import config from "../../../../config";
import { apiEndpoints } from "../../../common/api/apiEndpoints";
import axios from "axios";
import { DeleteApi } from "../../../common/api/api";
import { useParams } from "react-router-dom";

const CollectionCard = ({
  title = "",
  timeStamp = "",
  author = "",
  storyType = "",
  onClickSubmit = () => { },
  parentId = "",
  collectionOfCollectionList,
  FetchCollectionOfCollectionList
}) => {
  const [loader, setLoader] = useState(false);
  const { onDelete, loading } = useStoreHook();
  const { id } = useParams();

  const handleDelete = async () => {
    setLoader(true);
    if (collectionOfCollectionList?.length) {
      for (let item of collectionOfCollectionList) {
        if (parentId === item?.collection_id || parentId === item?.story_id) {
          const response = await DeleteApi(
            apiEndpoints.collectionOfCollectionById(item?._id)
          ).then((response) => {
            setLoader(false);
          });
        }
      }
    }

    const newList = collectionOfCollectionList.filter(item => !(parentId === item?.collection_id || parentId === item?.story_id))

    FetchCollectionOfCollectionList()

    const response = await axios.put(
      `${config.apiUrl}/${apiEndpoints.collectionById(id)}`,
      {
        is_collection_of_collection: (newList?.length > 0)
      },
    );
  };



  const handleSubmit = () => {
    onClickSubmit();
  };
  const ShowStar = (id) => {
    if (collectionOfCollectionList?.length) {
      for (let item of collectionOfCollectionList) {
        if (id === item?.collection_id || id === item?.story_id) {
          return true;
        }
      }
    }
    return false;
  };
  return (
    <>
      {loader ? <div className="spinner card-loader-position"></div> : ""}
      <div className="border px-15 py-10 bg-white">
        <div className="d-flex gap-2 flex-column">
          <div className="d-flex justify-content-between align-items-center">
            <Badge className="bg-secondary text-capitalize">
              {storyType ?? "default"}
            </Badge>
            {ShowStar(parentId) ? (
              <i
                className="fa fa-star fs-4 cursor-pointer"
                onClick={(e) => {
                  handleDelete();
                }}
              />
            ) : (
              <>
                {loading ? (
                  <i>
                    <div className="spinner"></div>
                  </i>
                ) : (
                  <i
                    className="fa fa-star-o fs-4 cursor-pointer"
                    onClick={() => handleSubmit()}
                  />
                )}
              </>
            )}
          </div>
          <div>
            <p>{title ?? "..."}</p>
          </div>
          <div className="d-flex gap-3 ">
            <span className="d-flex gap-2 align-items-center">
              <i className="fa fa-user-o fs-5" />
              <span>{author ?? "..."}</span>
            </span>
            <span className="d-flex gap-2 align-items-center">
              <i className="fa fa-clock-o fs-5" />
              <span>{timeStamp ?? "..."}</span>
            </span>
            <span className="d-flex gap-2 align-items-center">
              <i className="fa  fa-bar-chart fs-5" />
              <span>0</span>
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default CollectionCard;
