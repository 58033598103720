export const countWordsAndChars = (inputText) => {
  const withoutPTags = inputText.replace(/<\/?[^>]+(>|$)/g, "");

  const words = withoutPTags.trim().split(/\s+/);
  const characters = withoutPTags.length;
  return {
    wordCount: characters > 0 ? words?.length : 0,
    characters: characters,
  };
};

export const convertToSlug = (text) => {
  return text?.toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, "") // Remove special characters
    .replace(/\s+/g, "-") // Replace spaces with dashes
};