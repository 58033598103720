import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { postionOptionArr, typeArr } from "../../common/common-data/alphabets";
import axios from "axios";
import config from "../../../config";
import { apiEndpoints } from "../../common/api/apiEndpoints";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { GetListApi } from "../../common/api/api";

const useMenuHook = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  // search bar--select2 start form here
  const [menuSearchbar, setMenuSearchbar] = useState([
    { label: "All", value: "All" },
    { label: "Active ", value: "Active " },
    { label: "Inactive", value: "Inactive" },
  ]);

  // search bar--select2 end form here

  const [menuList, setMenuList] = useState([]);
  const [parentMenu, setParentMenu] = useState([]);
  const [valueTypes, setValueTypes] = useState([{ label: "", value: "" }]);
  const [postionOption, setPostionOption] = useState(postionOptionArr);
  const [parentSlug, setParentSlug] = useState('')

  const [selectedPosition, setSelectedPosition] = useState([
    { label: "", value: "" },
  ]);

  const [selectedType, setSelectedType] = useState([{ label: "", value: "" }]);
  const [selectedParentMenu, setSelectedParentMenu] = useState([
    { label: "", value: "" },
  ]);
  const [selectedTypeValue, setSelectedTypeValue] = useState([
    { label: "", value: "" },
  ]);
  const [typeMenu, setTypeMenu] = useState(typeArr);


  const getSectionParentChain = async (section_name) => {
    const url = `${config.apiUrl}/section?sectionName=${section_name}`
    const response = await fetch(url)
    const sectionData = await response.json()

    const section = sectionData?.sections?.[0]

    if (section?.parent_section?.length > 0) {
      return await getSectionParentChain(section?.parent_section?.[0]) + "/" + section?.section_name.replace(/ /g, '-').toLowerCase()
    }
    else {
      return section?.section_name.replace(/ /g, '-').toLowerCase()
    }
  }

  const setParentSlugFunction = async (id) => {
    const response = await fetch(`${config.apiUrl}/menus/${id}`)
    const parentMenu = await response.json()
    setParentSlug(parentMenu.slug || '')
  }

  const handlePosition = async (type, e) => {
    if (type === "position") {
      setValue("position", e?.value);
      setSelectedPosition(e);
    } else if (type === "type") {
      setValue("type", e?.value);
      setSelectedType(e);
    } else if (type === "type_value") {
      setValue("type_value", e?.value);
      setSelectedTypeValue(e);

      if (selectedType?.value === "Collection") {
        setValue("slug", e?.slug);
      } else if (selectedType?.value === "Pages") {
        setValue("slug", 'pages/' + e?.value.replace(/ /g, '-').toLowerCase());
      } else if (selectedType?.value === "Section") {
        const sectionSlug = await getSectionParentChain(e?.value);
        setValue("slug", sectionSlug);
      } else if (selectedType?.value === "Tags") {
        setValue("slug", `topic/${e.value}`);
      }
    } else if (type === "parent") {
      setParentSlugFunction(e?.value);
      setSelectedParentMenu(e);
      setValue("parent", e?.value);
    }
  };

  const onMenuSubmit = (data) => {
    try {
      const userData = sessionStorage.getItem("user");
      const user = userData ? JSON.parse(userData) : null;
      let dt = new Date();
      data.created_by = user?.name;
      data.updated_by = user?.name;
      data.created_on = dt;
      data.updated_on = dt;

      const response = axios
        .post(`${config.apiUrl}/${apiEndpoints?.menus}`, data)
        .then((res) => {
          if (res.data) {
            toast.success("Menu created successfully.");
            navigate("/menu");
          } else {
            toast.error(response.data.error);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  const onMenuUpdate = async (data) => {
    try {
      const userData = sessionStorage.getItem("user");
      const user = userData ? JSON.parse(userData) : null;
      let dt = new Date();
      data.updated_by = user?.name;
      data.updated_on = dt;

      const response = await axios
        .put(`${config.apiUrl}/${apiEndpoints?.menusById(id)}`, data)
        .then((response) => {
          if (response.data) {
            toast.success("Menu updated successfully.");
            navigate("/menu");
          } else {
            toast.error(response.data.error);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };
  const onMenuListUpdate = async (data) => {
    try {
      const userData = sessionStorage.getItem("user");
      const user = userData ? JSON.parse(userData) : null;
      let dt = new Date();
      data.updated_by = user?.name;
      data.updated_on = dt;

      const parentArr = []
      data?.originalOrder?.forEach(ele => {
        parentArr.push(ele.parent)
      })

      const response = await axios
        .put(`${config.apiUrl}/${apiEndpoints?.menus}`, {
          draggedFrom: data?.draggedFrom,
          draggedTo: data?.draggedTo,
          originalOrder: parentArr,
        })
        .then((response) => {
          if (response.data) {
            FetchMenuList();
            toast.success("Menu updated successfully.");
            setInterval(() => {
              window.location.reload()
            }, 1000)
          } else {
            toast.error(response.data.error);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };
  const FetchMenuList = async (name, type, type_value, status, position) => {
    let params = {
      name: name,
      type: type,
      type_value: type_value,
      status: status,
      position: position
    };
    const menu = await GetListApi(`menus`, params);
    if (menu?.length > 0) {
      setMenuList(menu);

      const parentMenus = menu.map(menu => {
        return {
          label: menu?.name,
          value: menu?._id
        }
      })
      setParentMenu(parentMenus)
    } else {
      setMenuList([]);
    }
  };

  useEffect(() => {
    FetchMenuList();
  }, []);

  const handleStatusChange = async (itemId, event) => {
    try {
      const userData = sessionStorage.getItem("user");
      const user = userData ? JSON.parse(userData) : null;
      let dt = new Date();
      let payload = {
        _id: itemId,
        status: event ? "Active" : "InActive",
        updated_by: user?.name,
        updated_on: dt,
      };

      const response = await axios
        .put(
          `${config.apiUrl}/${apiEndpoints?.menusById(payload?._id)}`,
          payload
        )
        .then((response) => {
          if (response.data) {
            FetchMenuList();
            toast.success("Menu updated successfully.");
          } else {
            toast.error(response.data.error);
          }
        });
    } catch (error) {
      console.log(error);
    }
    // setChecked(val);
  };
  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`${config.apiUrl}/${apiEndpoints.menusById(id)}`)
          .then((response) => {
            FetchMenuList();
            toast.success("Menu deleted successfully");
          })
          .catch((error) => {
            console.error("Error deleting item:", error);
            toast.error("An error occurred while deleting");
          });
      }
    });
  };

  const fetchMenuById = async (id) => {
    try {
      const response = await axios.get(
        `${config.apiUrl}/${apiEndpoints?.menusById(id)}`
      );
      if (response?.data) {
        let parentMenuLabel = null

        if (response?.data?.parent) {
          await axios.get(
            `${config.apiUrl}/${apiEndpoints?.menusById(response?.data?.parent)}`
          ).then(response => {
            parentMenuLabel = response?.data?.name
          })

        }
        setSelectedType({
          label: response.data.type,
          value: response.data.type,
        });
        setSelectedPosition({
          label: response.data.position,
          value: response.data.position,
        });
        setSelectedParentMenu({
          label: parentMenuLabel,
          value: response.data.parent,
        });
        setSelectedTypeValue({
          label: response.data.type_value,
          value: response.data.type_value,
        });
        setValue("position", response.data.position);
        setValue("name", response.data.name);
        setValue("new_tab", response.data.new_tab);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (id) {
      fetchMenuById(id);
    }
  }, [id]);

  useEffect(() => {
    if (menuList?.length > 0) {
      menuList?.map((item, index) => {
        if (index < menuList?.length)
          parentMenu?.push({ label: item?.name, value: item?._id });
      });
    }
  }, [menuList?.length]);

  //section
  const fetchOurSection = () => {
    axios
      .get(`${config.apiUrl}/section`, {
        params: {
          pageSize: 10000
        }
      })
      .then((response) => {
        let arr = [];
        if (response.data.sections.length > 0) {
          response.data.sections.forEach((element) => {
            arr?.push({
              label: element?.section_name,
              value: element?.section_name,
            });
            setValueTypes(arr);
          });
        }
      })
      .catch((error) => {
        console.error("Error fetching contact requests:", error);
      });
  };
  //collection
  const FetchCollectionList = async () => {
    try {
      const response = await axios
        .get(`${config.apiUrl}/${apiEndpoints.collection}`, {
          params: { page: 1, pageSize: 10000 },
        })
        .then((response) => {
          let arr = [];
          if (response?.data?.collections?.length > 0) {
            response?.data?.collections?.forEach((item) => {
              arr?.push({
                label: item?.title,
                value: item?.title,
                slug: item?.slug,
              });
              setValueTypes(arr);
            });
          }
        });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  //tags
  const FetchTagsList = async () => {
    try {
      const response = await axios
        .get(`${config.apiUrl}/${apiEndpoints.tags}`, {
          params: {},
        })
        .then((response) => {
          let arr = [];
          if (response.data.length > 0) {
            response.data.forEach((element) => {
              arr?.push({
                label: element?.name,
                value: element?.name,
              });
              setValueTypes(arr);
            });
          }
        });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  //call pages api
  const FetchPagesList = async () => {
    try {
      const response = await axios
        .get(`${config.apiUrl}/pages`, {
          params: {},
        })
        .then((response) => {
          let arr = [];
          console.log(response, "response");
          if (response.data.length > 0) {
            response.data.forEach((element) => {
              arr?.push({
                label: element?.title,
                value: element?.title,
              });
              setValueTypes(arr);
            });
          }
        });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  //collection of collection
  //call pages api
  const FetchCollectionOfCollectionList = async () => {
    // try {
    //   const response = await axios
    //     .get(`${config.apiUrl}/pages`, {
    //       params: {},
    //     })
    //     .then((response) => {
    //       let arr = [];
    //       console.log(response, "response");
    //       if (response.data.length > 0) {
    //         response.data.forEach((element) => {
    //           arr?.push({
    //             label: element?.title,
    //             value: element?.title,
    //           });
    //           setValueTypes(arr);
    //         });
    //       }
    //     });
    // } catch (error) {
    //   console.error("Error fetching data:", error);
    // }
  };
  useEffect(() => {
    if (selectedType?.value === "Section") {
      fetchOurSection();
    } else if (selectedType?.value === "Collection") {
      FetchCollectionList();
    } else if (selectedType?.value === "Tags") {
      FetchTagsList();
    } else if (selectedType?.value === "Pages") {
      FetchPagesList();
    } else if (selectedType?.value === "Collections Of Collection") {
      // FetchCollectionOfCollectionList();
    } else {
    }
  }, [selectedType]);

  return {
    register,
    handleSubmit,
    setValue,
    errors,

    postionOption,
    setPostionOption,
    handlePosition,
    valueTypes,
    setValueTypes,
    onMenuSubmit,
    menuList,
    setMenuList,
    typeMenu,
    parentMenu,
    setParentMenu,
    handleStatusChange,
    handleDelete,
    onMenuUpdate,
    onMenuListUpdate,
    id,
    fetchOurSection,
    FetchCollectionList,
    //selected state
    selectedType,
    selectedPosition,
    selectedParentMenu,
    selectedTypeValue,
    FetchMenuList,
  };
};

export default useMenuHook;
