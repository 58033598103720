import config from '../../../config';

// mandatory fields in payload: source, payload, error_message, url, http_method, story_id
const saveToLogs = async (payload) => {
    const user = JSON.parse(localStorage?.getItem('user'))

    const url = `${config.apiUrl}/logs`;

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                user_id: user?._id,
                ...payload
            }),
        });

        const data = await response.json();

        if (data?._id) {
            return { status: 'ok', message: 'logs created' };
        } else {
            return { status: 'failed', message: 'failed to create logs' };
        }
    } catch (error) {
        console.error('Error saving logs:', error);
        return { status: 'failed', message: 'failed to create logs' };
    }
};

export default saveToLogs;