import React, { useEffect, useState } from "react";
import moment from "moment";
import Pagination from "../../common/pagination";
import Loader from "../../common/utils/loader";
import { ConvertArrayTOString } from "../../common/utils/arrayToString";
import config from "../../../config";
import { apiEndpoints } from "../../common/api/apiEndpoints";
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { utils as XLSXUtils, writeFile as writeExcelFile } from 'xlsx';
import Select from 'react-select'
import makeAnimated from "react-select/animated";
const animatedComponents = makeAnimated();

const StoryReport = () => {
    const oneWeekAgo = moment(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)).format('YYYY-MM-DD')
    const today = moment(new Date()).format('YYYY-MM-DD')

    const [storiesList, setStoriesList] = useState([])
    const [authors, setAuthors] = useState([])
    const [loading, setLoading] = useState(false)
    const [selectedAuthor, setSelectedAuthor] = useState('')
    const [startDate, setStartDate] = useState(oneWeekAgo)
    const [endDate, setEndDate] = useState(today)
    const [totalViews, setTotalViews] = useState(0)
    const [totalPages, setTotalPages] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [dateChangeCount, setDateChangeCount] = useState(0)
    const [showDatePicker, setShowDatePicker] = useState(false)
    const pageSize = 100

    const [selectedDateRange, setSelectedDateRange] = useState([
        {
            startDate: new Date(Date.now() - 7 * 24 * 60 * 60 * 1000),
            endDate: new Date(),
            key: 'selection'
        }
    ]);

    const handleSelect = async (ranges) => {
        setCurrentPage(1)

        setSelectedDateRange([ranges.selection]);
        const sDate = ranges?.selection?.startDate
        const eDate = ranges?.selection?.endDate

        setStartDate(moment(sDate)?.format('YYYY-MM-DD'))
        setEndDate(moment(eDate)?.format('YYYY-MM-DD'))

        setDateChangeCount(prev => prev + 1)
    };

    useEffect(() => {
        if (selectedAuthor) fetchStories()
    }, [selectedAuthor])

    // useEffect(() => {
    //     if (startDate && endDate) fetchStories()
    // }, [startDate, endDate])

    useEffect(() => {
        if (dateChangeCount) fetchStories()
    }, [dateChangeCount])

    const fetchAuthors = async () => {
        try {
            const url = `${config.apiUrl}/${apiEndpoints?.user}`
            const response = await fetch(url)
            const data = await response.json()
            const arr = []
            data?.users.forEach((element) => {
                if (element?.user_role?.name === "author" || element?.user_role?.name === "Admin" || element?.user_role?.name === "Editor" || element?.user_role?.name === "Guest Contributor") {
                    arr?.push({
                        label: element?.name,
                        value: element?.name,
                        // id: element?._id,
                    });
                }
            });

            setAuthors(arr)
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }

    const fetchStories = async () => {
        try {
            setLoading(true)
            let url = `${config.apiUrl}/stories/top/stories?`

            if (selectedAuthor) url += `&author=${selectedAuthor}`
            if (startDate) url += `&startDate=${startDate}`
            if (endDate) url += `&endDate=${endDate}`

            url += `&page=${currentPage}&pageSize=${pageSize}`

            const response = await fetch(url)
            const data = await response.json()
            setStoriesList(data?.stories)
            setTotalPages(data?.totalPages)

            const views = data?.stories?.reduce((acc, current) => acc + (current?.range_count || 0), 0)
            setTotalViews(views)

            if (data?.stories) {
                console.log({ len: data?.stories?.length })
                setLoading(false)
                
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
        finally {
            // setLoading(false)
        }
    }

    const convertToExcel = (stories) => {
        const data = stories.map(item => ({
            title: item.title,
            author: item.authors?.[0]?.label || '',
            view_count: item?.range_count?.toString(),
            story_type: item?.story_type,
            published_date: moment(item?.publish_date).format("DD MMMM YYYY, hh:mm A"),
        }));

        const columnWidths = [
            { wch: 100 },
            { wch: 50 },
            { wch: 30 },
            { wch: 30 },
            { wch: 50 },
        ];

        const worksheet = XLSXUtils.json_to_sheet(data);
        worksheet['!cols'] = columnWidths;

        const workbook = XLSXUtils.book_new();
        XLSXUtils.book_append_sheet(workbook, worksheet, 'Sheet1');

        const sDate = moment(startDate).format('DD-MM-YYYY')
        const eDate = moment(endDate).format('DD-MM-YYY')
        writeExcelFile(workbook, `story_report_from_${sDate}_to_${eDate}.xlsx`);
    };

    useEffect(() => {
        const fetchData = async () => {
            fetchAuthors()
            // if (startDate && endDate) fetchStories()
        }
        fetchData()

        setTimeout(() => {
            const params = new URLSearchParams(window.location.search);
            const authorName = params.get('authorName');
            const startDateProp = params.get('startDate');
            const endDateProp = params.get('endDate');

            if (authorName) setSelectedAuthor(authorName)
            if (startDateProp) setStartDate(startDateProp)
            if (endDateProp) setEndDate(endDateProp)

            if (!authorName) {
                setDateChangeCount(prev => prev + 1)
            }

            if (startDateProp || endDateProp) {
                setSelectedDateRange([{
                    startDate: new Date(startDateProp),
                    endDate: new Date(endDateProp),
                    key: 'selection'
                }])
            }
        }, [1000])

    }, [])

    const checkStoryIsPublished = (story) => {
        if (story?.status === 'scheduled') {
            const currentTime = new Date().toISOString()
            const publishTime = story?.publish_date

            if (publishTime && publishTime <= currentTime) {
                return true
            }
        }
        return false
    }

    const handlePageChange = async (pageNumber) => {
        setCurrentPage(pageNumber)
    }

    useEffect(() => {
        if (totalPages) {
            setStoriesList([])
            fetchStories()
        }
    }, [currentPage])

    return (
        <div style={{ backgroundColor: '#f9f9f9' }}>
            <div className="content" style={{ backgroundColor: '#f9f9f9' }}>
                <div className="content">
                    <div className="card col-md-12 m-auto p-30">

                        <div className="card-body">
                            <div className='authors-container'>
                                <Select
                                    options={authors}
                                    components={animatedComponents}
                                    value={authors?.find(author => author.value === selectedAuthor)}
                                    placeholder="Select Authors"
                                    onChange={e => {
                                        setCurrentPage(1)
                                        setSelectedAuthor(e.value)
                                    }}
                                />
                            </div>

                            <div className='export-excel-btn'>
                                {
                                    storiesList?.length > 0 &&
                                    <button className="btn-primary export-btn" onClick={() => convertToExcel(storiesList)}>Export to Excel</button>
                                }
                                <div className="fw-bold"><span className=" mr-4">{"Total Views:"}</span>{totalViews}</div>
                            </div>

                            <button className="btn-primary mb-40" onClick={() => { setShowDatePicker(!showDatePicker) }}>Select Date Range</button>

                            {
                                showDatePicker &&
                                <div className="date-picker mb-20">
                                    <DateRangePicker
                                        ranges={selectedDateRange}
                                        onChange={handleSelect}
                                    />
                                </div>
                            }


                            <div className="table-responsive">
                                <table className="table table-hover">
                                    <tbody>
                                        {!storiesList?.length > 0 || loading ? (
                                            <Loader />
                                        ) : (
                                            storiesList?.map((story, index) => {
                                                return (
                                                    <tr key={story?._id}>
                                                        <td style={{ lineHeight: '0.8em', widht: '95%' }} >

                                                            <div style={{ display: 'flex', justifyContent: 'left', }}>
                                                                <p className="text-capitalize" style={{ backgroundColor: "grey", padding: '3px', textTransform: 'capitalize', fontSize: '10px', fontWeight: '500', color: 'white', borderRadius: '2px' }}>
                                                                    {checkStoryIsPublished(story) ? 'PUBLISHED' : story?.status.toString().toUpperCase()}
                                                                </p>
                                                                <p className="story-type" style={{ marginLeft: '10px', backgroundColor: "#DCDCDC", padding: '3px', textTransform: 'capitalize', fontSize: '10px', fontWeight: '500', color: 'black', borderRadius: '2px' }}>
                                                                    {story?.story_type?.toLowerCase()?.split(' ')?.[0]?.toUpperCase()}
                                                                </p>

                                                            </div>

                                                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                <p
                                                                    onMouseLeave={(e) => e.target.style.color = 'initial'}
                                                                    style={{ cursor: 'pointer', fontSize: '1.3rem', lineHeight: '1.4' }}
                                                                >
                                                                    {story?.title}
                                                                </p>

                                                                <div style={{ marginRight: '8%' }}>
                                                                    <p>
                                                                        Views
                                                                    </p>
                                                                    <p style={{ textAlign: 'center', fontWeight: 'bold', fontSize: '15px' }}>{story?.range_count}</p>
                                                                </div>
                                                            </div>


                                                            <div style={{ display: 'flex', justifyContent: 'left', fontSize: '1rem', height: '1.2em' }}>

                                                                <p className="text-capitalize story-author" style={{ color: '#5f6978' }}>
                                                                    <svg style={{ marginRight: '5px' }} data-test-id="user" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" width="16" fill="#5f6978" height="16" viewBox="0 0 24 24"><path fill="transparent" d="M.03.13h24v24h-24z"></path><path d="M18.18 14.27A3.69 3.69 0 0 1 20.43 16a3.91 3.91 0 0 1 .57 2v1a2.15 2.15 0 0 1-.67 1.58 2.17 2.17 0 0 1-1.58.67H5.28a2.17 2.17 0 0 1-1.58-.65A2.15 2.15 0 0 1 3 19v-1a4.13 4.13 0 0 1 .6-2.11 3.74 3.74 0 0 1 2.25-1.65l1.72-.49a6.2 6.2 0 0 1-1.48-2.5 6.45 6.45 0 0 1-.28-1.83A6.15 6.15 0 0 1 12 3.27a6.16 6.16 0 0 1 6.19 6.18 6.45 6.45 0 0 1-.28 1.83 6.09 6.09 0 0 1-1.48 2.5zM19.91 19v-1a2.72 2.72 0 0 0-.57-1.67 2.76 2.76 0 0 0-1.47-1l-2.46-.71a6.09 6.09 0 0 1-6.75 0l-2.46.71a2.79 2.79 0 0 0-1.48 1A2.71 2.71 0 0 0 4.16 18v1a1.13 1.13 0 0 0 1.12 1.12h13.5a1.07 1.07 0 0 0 .79-.33 1.1 1.1 0 0 0 .34-.79zM12 4.39a4.86 4.86 0 0 0-2.53.69 5.18 5.18 0 0 0-1.82 1.84 5.07 5.07 0 0 0 0 5.07 5.26 5.26 0 0 0 1.85 1.84 5 5 0 0 0 5.06 0A5.18 5.18 0 0 0 16.41 12a4.89 4.89 0 0 0 .68-2.54 4.88 4.88 0 0 0-.68-2.53 5.1 5.1 0 0 0-1.85-1.84 4.83 4.83 0 0 0-2.56-.7z"></path></svg>
                                                                    {story?.authors?.length > 0
                                                                        ? ConvertArrayTOString(
                                                                            story?.authors,
                                                                            "collection"
                                                                        )
                                                                        : "-"}
                                                                </p>

                                                                <p style={{ color: '#5f6978', marginLeft: '15px' }}>
                                                                    <svg style={{ marginRight: '5px' }} width="16" height="16" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#5F6978"><path d="M12.18 3.22a8.52 8.52 0 0 1 4.36 1.18 8.81 8.81 0 0 1 3.18 3.18 8.42 8.42 0 0 1 1.18 4.36 8.4 8.4 0 0 1-1.18 4.36 8.66 8.66 0 0 1-3.18 3.18 8.64 8.64 0 0 1-8.72 0 8.74 8.74 0 0 1-3.18-3.18 8.4 8.4 0 0 1-1.18-4.36 8.42 8.42 0 0 1 1.18-4.36A8.89 8.89 0 0 1 7.82 4.4a8.52 8.52 0 0 1 4.36-1.18zm7.59 8.72a7.4 7.4 0 0 0-1-3.81 7.61 7.61 0 0 0-6.59-3.78 7.35 7.35 0 0 0-3.79 1A7.64 7.64 0 0 0 4.59 12a7.39 7.39 0 0 0 1 3.8 7.61 7.61 0 0 0 6.59 3.78 7.39 7.39 0 0 0 3.8-1A7.66 7.66 0 0 0 19.75 12zM14.54 15a.44.44 0 0 0 .31.09.42.42 0 0 0 .28-.16l.29-.42a.43.43 0 0 0 .08-.3.35.35 0 0 0-.16-.27l-2.49-1.83V7a.41.41 0 0 0-.12-.3.44.44 0 0 0-.3-.12h-.5a.4.4 0 0 0-.29.12.38.38 0 0 0-.13.3v5.63a.33.33 0 0 0 .18.31z"></path></svg>
                                                                    {moment(story?.publish_date).format("DD MMMM YYYY, hh:mm A")}
                                                                </p>


                                                            </div>
                                                        </td>
                                                    </tr>
                                                );
                                            })
                                        )}
                                    </tbody>
                                </table>

                            </div>


                            <div className={`container`}>
                                <div className={`row`}>
                                    <div
                                        className={`col-sm-12 d-flex justify-content-center px-0`}
                                    >
                                        {
                                            storiesList?.length > 0 &&
                                            <Pagination
                                                currentPage={currentPage}
                                                totalPages={totalPages}
                                                onPageChange={handlePageChange}
                                            />
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default StoryReport;
