import React, { useEffect, useState } from "react";
import { Trash } from "react-bootstrap-icons";
import { Pencil } from "react-bootstrap-icons";
import { Eye } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import ReactSwitch from "react-switch";
import SectionUpdate from "../sections/SectionUpdate";
import useMenuHook from "./menuHook";
import Loader from "../../common/utils/loader";
import { Badge } from "react-bootstrap";
import { CheckAccess } from "../../common/utils/roleAccess";
import axios from "axios";
import config from "../../../config";
import { apiEndpoints } from "../../common/api/apiEndpoints";

// import Select from "react-select/dist/declarations/src/Select";

function NestedDragable(data) {
  const [list, setList] = useState()

  useEffect(() => {
    setList(data?.data)
  }, [data])

  const marginLeft = data?.marginLeft || '0px'
  const nested = data?.isNested || false

  const {
    menuList,
    setMenuList,
    handleDelete,
    FetchMenuList,
    onMenuListUpdate,
  } = useMenuHook();

  const initialDnDState = {
    draggedFrom: null,
    draggedTo: null,
    isDragging: false,
    originalOrder: [],
    updatedOrder: [],
  };
  const items = [];
  const [dragAndDrop, setDragAndDrop] = useState(initialDnDState);

  const getNestedPaylod = (parentData, menu, key) => {
    const nestedPayload = []

    parentData?.forEach(child => {
      nestedPayload.push({ parent: child, children: [] })
    })
    return nestedPayload
  }


  const onDragStart = (event) => {
    const initialPosition = Number(event.currentTarget.dataset.position);
    setDragAndDrop({
      ...dragAndDrop,
      draggedFrom: initialPosition,
      isDragging: true,
      originalOrder: list,
    });
    // event.dataTransfer.setData("text/html", '');
  };

  const onDragOver = (event) => {
    event.preventDefault();
    let newList = dragAndDrop.originalOrder;
    const draggedFrom = dragAndDrop.draggedFrom;
    const draggedTo = Number(event.currentTarget.dataset.position);
    const itemDragged = newList[draggedFrom];
    const remainingItems = newList.filter(
      (item, index) => index !== draggedFrom
    );

    newList = [
      ...remainingItems.slice(0, draggedTo),
      itemDragged,
      ...remainingItems.slice(draggedTo),
    ];

    if (draggedTo !== dragAndDrop.draggedTo) {
      setDragAndDrop({
        ...dragAndDrop,
        updatedOrder: newList,
        draggedTo: draggedTo,
      });
    }
  };
  const onDrop = (event) => {
    const fromId = dragAndDrop.draggedFrom
    const toId = dragAndDrop.draggedTo

    if (fromId === null || fromId === undefined || toId === null || toId === undefined) return

    setList(dragAndDrop.updatedOrder);

    const fromParentId = dragAndDrop.originalOrder[fromId].parent._id

    const toParentId = dragAndDrop.originalOrder[toId].parent._id

    const updateChild = async () => {
      for (const fromChild of dragAndDrop.originalOrder[fromId].children) {
        const response = await axios.put(`${config.apiUrl}/${apiEndpoints?.menusById(fromChild._id)}`, {
          ...fromChild,
          parent: toParentId
        });
      }

      for (const toChild of dragAndDrop.originalOrder[toId].children) {
        const response = await axios.put(`${config.apiUrl}/${apiEndpoints?.menusById(toChild._id)}`, {
          ...toChild,
          parent: fromParentId
        });
      }
    }

    updateChild()

    setDragAndDrop({
      ...dragAndDrop,
      draggedFrom: null,
      draggedTo: null,
      isDragging: false,
    });
    onMenuListUpdate(dragAndDrop);
  };

  const onDragLeave = () => {
    setDragAndDrop({
      ...dragAndDrop,
      draggedTo: null,
    });
  };

  return (
    <>
      <div className={`${nested ? "" : 'content'}`}>
        <div className="">
          <div >
            {/* <div className="card-header">
              <h3 className="text-bold">{pageTitle}</h3>
              {CheckAccess("menu", "create") && (
                <div className="timeline-footer text-right">
                  <Link
                    to="/menu/create"
                    className="btn btn-primary btn-round btn-sm"
                  >
                    Add Menu
                  </Link>
                </div>
              )}
            </div> */}
            <div className="card-body">
              <div className=" col-md-12">
                <ul className={`list-style-none dragNdrop`}>
                  {/* <li>
                    <div className={`row fs-5 fst-normal fw-bold`}>
                      <div className={`col-md-2 py-4 text-center`}> #</div>
                      <div className={`col-md-2 py-4`}>Name</div>
                      <div className={`col-md-2 py-4`}>Type</div>
                      <div className={`col-md-2 py-4`}>Type Value</div>
                      <div className={`col-md-2 py-4`}>Status</div>
                      <div className={`col-md-2 py-4`}>Action</div>
                    </div>
                  </li> */}
                  <li>
                    {/* <div className={`row fs-5 fst-normal fw-bold`}>
                      <div className={`col-md-2 py-4 text-center`}> </div>
                      <div className={`col-md-2 py-4`}>
                        {" "}
                        <input
                          type="text"
                          className="form-control"
                          onChange={(e) => {
                            FetchMenuList(e.target.value, "", "", "");
                          }}
                          data-table="your-table"
                          placeholder="Item to search.."
                        />
                      </div>
                      <div className={`col-md-2 py-4`}>
                        <input
                          type="text"
                          className="form-control"
                          onChange={(e) => {
                            FetchMenuList("", e.target.value, "", "");
                          }}
                          data-table="your-table"
                          placeholder="Item to search.."
                        />
                      </div>
                      <div className={`col-md-2 py-4`}>
                        <input
                          type="text"
                          className="form-control"
                          onChange={(e) => {
                            FetchMenuList("", "", e.target.value, "");
                          }}
                          data-table="your-table"
                          placeholder="Item to search.."
                        />
                      </div>
                      <div className={`col-md-2 py-4`}>
                        <select
                          name="status"
                          className="form-control"
                          onChange={(e) => {
                            FetchMenuList("", "", "", e.target.value);
                          }}
                        >
                          <option value={""}>All</option>
                          <option value={"Active"}>Active</option>
                          <option value={"Inactive"}>Inactive</option>
                        </select>
                      </div>
                      <div className={`col-md-2 py-4`}></div>
                    </div> */}
                  </li>

                  {!list?.length > 0 ? (
                    <Loader />
                  ) : (
                    list?.map((ele, key) => {
                      const menu = ele.parent
                      const children = ele.children
                      return (
                        <>
                          <li
                            key={key}
                            data-position={key}
                            draggable
                            onDragStart={onDragStart}
                            onDragOver={onDragOver}
                            onDrop={onDrop}
                            onDragLeave={onDragLeave}
                            style={{ marginLeft: marginLeft, backgroundColor: 'white', border: '1px solid #FAFAFA' }}
                          >
                            <div className={`row`} style={{ marginTop: '10px' }}>
                              <div
                                className={`col-md-2 d-flex justify-content-center`}
                              >
                                <div className={`srnoDiv shadow-sm`}>
                                  {/* {key + 1} */}
                                </div>
                              </div>
                              <div className={`col-md-2`}>
                                {menu?.name || `-`}
                              </div>
                              <div className={`col-md-2`}>{menu?.type}</div>
                              <div className={`col-md-2`}>{menu?.type_value}</div>
                              <div className={`col-md-1`}>
                                <div>
                                  <Badge
                                    bg={
                                      menu.status === "Active"
                                        ? "success"
                                        : "danger"
                                    }
                                    text="white"
                                  >
                                    {menu.status}
                                  </Badge>
                                </div>
                              </div>
                              <div className={`col-md-1`}>{menu?.position}</div>
                              <div className={`col-md-2`}>
                                <div className={`d-flex align-items-center`}>
                                  {CheckAccess("menu", "update") && (
                                    <Link to={`/menu/update/${menu?._id}`}>
                                      <button type="button" className="btn btn">
                                        <Pencil
                                          color="royalblue"
                                          size={16}
                                          data-toggle="tooltip"
                                          title="Edit"
                                        />
                                      </button>
                                    </Link>
                                  )}
                                  {CheckAccess("menu", "delete") && (
                                    <button
                                      type="button"
                                      className="btn btn"
                                      onClick={() => handleDelete(menu?._id)}
                                    >
                                      <Trash
                                        color="royalblue"
                                        size={16}
                                        data-toggle="tooltip"
                                        title="Delete"
                                      />
                                    </button>
                                  )}
                                </div>
                              </div>
                            </div>
                            {/* <NestedDragable data={getNestedPaylod(ele.children)} isNested={true} /> */}

                          </li>

                          {/* <NestedDragable data={getNestedPaylod(ele.children)} isNested={true} /> */}

                          {
                            children?.length > 0 ?
                              <NestedDragable data={getNestedPaylod(children, menu, key)} isNested={true} /> :
                              null
                          }
                        </>
                      );
                    })
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default NestedDragable;
